// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type { ApiErrorResponse, } from '../models';
import { ApiErrorResponseFromJSON, ApiErrorResponseToJSON, ApiErrorResponseValidator, } from '../models';
import * as models from '../models';
/* LIGHTLY CUSTOM START -> -> moved from bottom to top:  apiMustache */
/* LIGHTLY CUSTOM END -> moved from bottom to top: apiMustache */
export interface GetTokensRequestParams {
}
export function GetTokensRequestParamsSanitizer(json: any): GetTokensRequestParams {
    return {};
}
export const GetTokensRequestParamsValidatorShape = {};
export const GetTokensRequestParamsValidator = yup.object().shape(GetTokensRequestParamsValidatorShape);
export interface ResetTokenRequestParams {
}
export function ResetTokenRequestParamsSanitizer(json: any): ResetTokenRequestParams {
    return {};
}
export const ResetTokenRequestParamsValidatorShape = {};
export const ResetTokenRequestParamsValidator = yup.object().shape(ResetTokenRequestParamsValidatorShape);
export interface GetTokensRequestQuery {
}
export function GetTokensRequestQuerySanitizer(json: any): GetTokensRequestQuery {
    return {};
}
export const GetTokensRequestQueryValidatorShape = {};
export const GetTokensRequestQueryValidator = yup.object().shape(GetTokensRequestQueryValidatorShape);
export interface ResetTokenRequestQuery {
}
export function ResetTokenRequestQuerySanitizer(json: any): ResetTokenRequestQuery {
    return {};
}
export const ResetTokenRequestQueryValidatorShape = {};
export const ResetTokenRequestQueryValidator = yup.object().shape(ResetTokenRequestQueryValidatorShape);
/*
HANDLERS:



/v1/tokens

export const getTokensFN: RequestHandler<
    GetTokensRequestParams,
    string | ApiErrorResponse,
    GetTokensRequestBody,
    GetTokensRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetTokensRequestBodySanitizer, GetTokensRequestBodyValidator);
    const {
    } =  await validateAndSanitize(req.params, GetTokensRequestParamsSanitizer, GetTokensRequestParamsValidator);
    const {
    } = await validateAndSanitize(req.query, GetTokensRequestQuerySanitizer, GetTokensRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        stringStringify,
        validateAndRespond({ id, ...rest }, stringFromJSON, stringValidator),
    );
}



/v1/tokens

export const resetTokenFN: RequestHandler<
    ResetTokenRequestParams,
    string | ApiErrorResponse,
    ResetTokenRequestBody,
    ResetTokenRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, ResetTokenRequestBodySanitizer, ResetTokenRequestBodyValidator);
    const {
    } =  await validateAndSanitize(req.params, ResetTokenRequestParamsSanitizer, ResetTokenRequestParamsValidator);
    const {
    } = await validateAndSanitize(req.query, ResetTokenRequestQuerySanitizer, ResetTokenRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        stringStringify,
        validateAndRespond({ id, ...rest }, stringFromJSON, stringValidator),
    );
}


*/
/* LIGHTLY CUSTOM END -> api */
/**
 * AuthApi - interface
 *
 * @export
 * @interface AuthApiInterface
 */
export interface AuthApiInterface {
    /**
     * Get auth token of the current user
     * @summary Get auth token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    getTokensRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;
    /**
     * Get auth token of the current user
     * Get auth token
     */
    getTokens(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;
    /**
     * Reset token of user and generate a new one. This is not ideal because the user does not have a transition time between the old and new token (e.g for this pipelines). Also, the token should be a JWT or similar.
     * @summary Reset user token
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    resetTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;
    /**
     * Reset token of user and generate a new one. This is not ideal because the user does not have a transition time between the old and new token (e.g for this pipelines). Also, the token should be a JWT or similar.
     * Reset user token
     */
    resetToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;
}
/**
 *
 */
export class AuthApi extends runtime.BaseAPI implements AuthApiInterface {
    /**
     * Get auth token of the current user
     * Get auth token
     */
    async getTokensRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/tokens`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.TextApiResponse(response) as any;
    }
    /**
     * Get auth token of the current user
     * Get auth token
     */
    async getTokens(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getTokensRaw(initOverrides);
        return await response.value();
    }
    /**
     * Reset token of user and generate a new one. This is not ideal because the user does not have a transition time between the old and new token (e.g for this pipelines). Also, the token should be a JWT or similar.
     * Reset user token
     */
    async resetTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/tokens`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.TextApiResponse(response) as any;
    }
    /**
     * Reset token of user and generate a new one. This is not ideal because the user does not have a transition time between the old and new token (e.g for this pipelines). Also, the token should be a JWT or similar.
     * Reset user token
     */
    async resetToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.resetTokenRaw(initOverrides);
        return await response.value();
    }
}
