// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type { ApiErrorResponse, CreateEntityResponse, FileNameFormat, FileOutputFormat, FilenameAndReadUrls, InitialTagCreateRequest, LabelBoxDataRows, LabelBoxV4DataRows, LabelStudioTasks, SamaTasks, TagArithmeticsRequest, TagArithmeticsResponse, TagBitMaskResponse, TagCreateRequest, TagData, TagFilenamesData, TagUpdateRequest, TagUpsizeRequest, } from '../models';
import { ApiErrorResponseFromJSON, ApiErrorResponseToJSON, ApiErrorResponseValidator, CreateEntityResponseFromJSON, CreateEntityResponseToJSON, CreateEntityResponseValidator, FileNameFormatFromJSON, FileNameFormatToJSON, FileNameFormatValidator, FileOutputFormatFromJSON, FileOutputFormatToJSON, FileOutputFormatValidator, FilenameAndReadUrlsFromJSON, FilenameAndReadUrlsToJSON, FilenameAndReadUrlsValidator, InitialTagCreateRequestFromJSON, InitialTagCreateRequestToJSON, InitialTagCreateRequestValidator, LabelBoxDataRowsFromJSON, LabelBoxDataRowsToJSON, LabelBoxDataRowsValidator, LabelBoxV4DataRowsFromJSON, LabelBoxV4DataRowsToJSON, LabelBoxV4DataRowsValidator, LabelStudioTasksFromJSON, LabelStudioTasksToJSON, LabelStudioTasksValidator, SamaTasksFromJSON, SamaTasksToJSON, SamaTasksValidator, TagArithmeticsRequestFromJSON, TagArithmeticsRequestToJSON, TagArithmeticsRequestValidator, TagArithmeticsResponseFromJSON, TagArithmeticsResponseToJSON, TagArithmeticsResponseValidator, TagBitMaskResponseFromJSON, TagBitMaskResponseToJSON, TagBitMaskResponseValidator, TagCreateRequestFromJSON, TagCreateRequestToJSON, TagCreateRequestValidator, TagDataFromJSON, TagDataToJSON, TagDataValidator, TagFilenamesDataFromJSON, TagFilenamesDataToJSON, TagFilenamesDataValidator, TagUpdateRequestFromJSON, TagUpdateRequestToJSON, TagUpdateRequestValidator, TagUpsizeRequestFromJSON, TagUpsizeRequestToJSON, TagUpsizeRequestValidator, } from '../models';
import * as models from '../models';
/* LIGHTLY CUSTOM START -> -> moved from bottom to top:  apiMustache */
/* LIGHTLY CUSTOM END -> moved from bottom to top: apiMustache */
export interface CreateInitialTagByDatasetIdRequest {
    initialTagCreateRequest: models.InitialTagCreateRequest;
    datasetId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const CreateInitialTagByDatasetIdRequestValidatorShape = {
    'initialTagCreateRequest': models.InitialTagCreateRequestValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const CreateInitialTagByDatasetIdRequestValidator = yup.object().shape(CreateInitialTagByDatasetIdRequestValidatorShape);
export interface CreateTagByDatasetIdRequest {
    tagCreateRequest: models.TagCreateRequest;
    datasetId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const CreateTagByDatasetIdRequestValidatorShape = {
    'tagCreateRequest': models.TagCreateRequestValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const CreateTagByDatasetIdRequestValidator = yup.object().shape(CreateTagByDatasetIdRequestValidatorShape);
export interface DeleteTagByTagIdRequest {
    datasetId: string;
    tagId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const DeleteTagByTagIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const DeleteTagByTagIdRequestValidator = yup.object().shape(DeleteTagByTagIdRequestValidatorShape);
export interface DownloadZipOfSamplesByTagIdRequest {
    datasetId: string;
    tagId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const DownloadZipOfSamplesByTagIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const DownloadZipOfSamplesByTagIdRequestValidator = yup.object().shape(DownloadZipOfSamplesByTagIdRequestValidatorShape);
export interface ExportTagToBasicFilenamesRequest {
    datasetId: string;
    tagId: string;
    expiresIn?: number;
    accessControl?: string;
    fileNameFormat?: models.FileNameFormat;
    includeMetaData?: boolean;
    format?: models.FileOutputFormat;
    previewExample?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const ExportTagToBasicFilenamesRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'expiresIn': yup.number().integer(),
    'accessControl': yup.string(),
    'fileNameFormat': models.FileNameFormatValidator.optional().default(undefined),
    'includeMetaData': yup.boolean(),
    'format': models.FileOutputFormatValidator.optional().default(undefined),
    'previewExample': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const ExportTagToBasicFilenamesRequestValidator = yup.object().shape(ExportTagToBasicFilenamesRequestValidatorShape);
export interface ExportTagToBasicFilenamesAndReadUrlsRequest {
    datasetId: string;
    tagId: string;
    format?: models.FileOutputFormat;
    previewExample?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const ExportTagToBasicFilenamesAndReadUrlsRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'format': models.FileOutputFormatValidator.optional().default(undefined),
    'previewExample': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const ExportTagToBasicFilenamesAndReadUrlsRequestValidator = yup.object().shape(ExportTagToBasicFilenamesAndReadUrlsRequestValidatorShape);
export interface ExportTagToLabelBoxDataRowsRequest {
    datasetId: string;
    tagId: string;
    expiresIn?: number;
    accessControl?: string;
    fileNameFormat?: models.FileNameFormat;
    includeMetaData?: boolean;
    format?: models.FileOutputFormat;
    previewExample?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const ExportTagToLabelBoxDataRowsRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'expiresIn': yup.number().integer(),
    'accessControl': yup.string(),
    'fileNameFormat': models.FileNameFormatValidator.optional().default(undefined),
    'includeMetaData': yup.boolean(),
    'format': models.FileOutputFormatValidator.optional().default(undefined),
    'previewExample': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const ExportTagToLabelBoxDataRowsRequestValidator = yup.object().shape(ExportTagToLabelBoxDataRowsRequestValidatorShape);
export interface ExportTagToLabelBoxV4DataRowsRequest {
    datasetId: string;
    tagId: string;
    expiresIn?: number;
    accessControl?: string;
    fileNameFormat?: models.FileNameFormat;
    includeMetaData?: boolean;
    format?: models.FileOutputFormat;
    previewExample?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const ExportTagToLabelBoxV4DataRowsRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'expiresIn': yup.number().integer(),
    'accessControl': yup.string(),
    'fileNameFormat': models.FileNameFormatValidator.optional().default(undefined),
    'includeMetaData': yup.boolean(),
    'format': models.FileOutputFormatValidator.optional().default(undefined),
    'previewExample': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const ExportTagToLabelBoxV4DataRowsRequestValidator = yup.object().shape(ExportTagToLabelBoxV4DataRowsRequestValidatorShape);
export interface ExportTagToLabelStudioTasksRequest {
    datasetId: string;
    tagId: string;
    expiresIn?: number;
    accessControl?: string;
    fileNameFormat?: models.FileNameFormat;
    includeMetaData?: boolean;
    format?: models.FileOutputFormat;
    previewExample?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const ExportTagToLabelStudioTasksRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'expiresIn': yup.number().integer(),
    'accessControl': yup.string(),
    'fileNameFormat': models.FileNameFormatValidator.optional().default(undefined),
    'includeMetaData': yup.boolean(),
    'format': models.FileOutputFormatValidator.optional().default(undefined),
    'previewExample': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const ExportTagToLabelStudioTasksRequestValidator = yup.object().shape(ExportTagToLabelStudioTasksRequestValidatorShape);
export interface ExportTagToSamaTasksRequest {
    datasetId: string;
    tagId: string;
    expiresIn?: number;
    accessControl?: string;
    fileNameFormat?: models.FileNameFormat;
    includeMetaData?: boolean;
    format?: models.FileOutputFormat;
    previewExample?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const ExportTagToSamaTasksRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'expiresIn': yup.number().integer(),
    'accessControl': yup.string(),
    'fileNameFormat': models.FileNameFormatValidator.optional().default(undefined),
    'includeMetaData': yup.boolean(),
    'format': models.FileOutputFormatValidator.optional().default(undefined),
    'previewExample': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const ExportTagToSamaTasksRequestValidator = yup.object().shape(ExportTagToSamaTasksRequestValidatorShape);
export interface GetFilenamesByTagIdRequest {
    datasetId: string;
    tagId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetFilenamesByTagIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetFilenamesByTagIdRequestValidator = yup.object().shape(GetFilenamesByTagIdRequestValidatorShape);
export interface GetTagByTagIdRequest {
    datasetId: string;
    tagId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetTagByTagIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetTagByTagIdRequestValidator = yup.object().shape(GetTagByTagIdRequestValidatorShape);
export interface GetTagsByDatasetIdRequest {
    datasetId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetTagsByDatasetIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetTagsByDatasetIdRequestValidator = yup.object().shape(GetTagsByDatasetIdRequestValidatorShape);
export interface PerformTagArithmeticsRequest {
    tagArithmeticsRequest: models.TagArithmeticsRequest;
    datasetId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const PerformTagArithmeticsRequestValidatorShape = {
    'tagArithmeticsRequest': models.TagArithmeticsRequestValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const PerformTagArithmeticsRequestValidator = yup.object().shape(PerformTagArithmeticsRequestValidatorShape);
export interface PerformTagArithmeticsBitmaskRequest {
    tagArithmeticsRequest: models.TagArithmeticsRequest;
    datasetId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const PerformTagArithmeticsBitmaskRequestValidatorShape = {
    'tagArithmeticsRequest': models.TagArithmeticsRequestValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const PerformTagArithmeticsBitmaskRequestValidator = yup.object().shape(PerformTagArithmeticsBitmaskRequestValidatorShape);
export interface UpdateTagByTagIdRequest {
    tagUpdateRequest: models.TagUpdateRequest;
    datasetId: string;
    tagId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const UpdateTagByTagIdRequestValidatorShape = {
    'tagUpdateRequest': models.TagUpdateRequestValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const UpdateTagByTagIdRequestValidator = yup.object().shape(UpdateTagByTagIdRequestValidatorShape);
export interface UpsizeTagsByDatasetIdRequest {
    tagUpsizeRequest: models.TagUpsizeRequest;
    datasetId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const UpsizeTagsByDatasetIdRequestValidatorShape = {
    'tagUpsizeRequest': models.TagUpsizeRequestValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const UpsizeTagsByDatasetIdRequestValidator = yup.object().shape(UpsizeTagsByDatasetIdRequestValidatorShape);
export interface CreateInitialTagByDatasetIdRequestParams {
    datasetId: string;
}
export function CreateInitialTagByDatasetIdRequestParamsSanitizer(json: any): CreateInitialTagByDatasetIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const CreateInitialTagByDatasetIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const CreateInitialTagByDatasetIdRequestParamsValidator = yup.object().shape(CreateInitialTagByDatasetIdRequestParamsValidatorShape);
export interface CreateTagByDatasetIdRequestParams {
    datasetId: string;
}
export function CreateTagByDatasetIdRequestParamsSanitizer(json: any): CreateTagByDatasetIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const CreateTagByDatasetIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const CreateTagByDatasetIdRequestParamsValidator = yup.object().shape(CreateTagByDatasetIdRequestParamsValidatorShape);
export interface DeleteTagByTagIdRequestParams {
    datasetId: string;
    tagId: string;
}
export function DeleteTagByTagIdRequestParamsSanitizer(json: any): DeleteTagByTagIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'tagId': json['tagId'],
    };
}
export const DeleteTagByTagIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const DeleteTagByTagIdRequestParamsValidator = yup.object().shape(DeleteTagByTagIdRequestParamsValidatorShape);
export interface DownloadZipOfSamplesByTagIdRequestParams {
    datasetId: string;
    tagId: string;
}
export function DownloadZipOfSamplesByTagIdRequestParamsSanitizer(json: any): DownloadZipOfSamplesByTagIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'tagId': json['tagId'],
    };
}
export const DownloadZipOfSamplesByTagIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const DownloadZipOfSamplesByTagIdRequestParamsValidator = yup.object().shape(DownloadZipOfSamplesByTagIdRequestParamsValidatorShape);
export interface ExportTagToBasicFilenamesRequestParams {
    datasetId: string;
    tagId: string;
}
export function ExportTagToBasicFilenamesRequestParamsSanitizer(json: any): ExportTagToBasicFilenamesRequestParams {
    return {
        'datasetId': json['datasetId'],
        'tagId': json['tagId'],
    };
}
export const ExportTagToBasicFilenamesRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const ExportTagToBasicFilenamesRequestParamsValidator = yup.object().shape(ExportTagToBasicFilenamesRequestParamsValidatorShape);
export interface ExportTagToBasicFilenamesAndReadUrlsRequestParams {
    datasetId: string;
    tagId: string;
}
export function ExportTagToBasicFilenamesAndReadUrlsRequestParamsSanitizer(json: any): ExportTagToBasicFilenamesAndReadUrlsRequestParams {
    return {
        'datasetId': json['datasetId'],
        'tagId': json['tagId'],
    };
}
export const ExportTagToBasicFilenamesAndReadUrlsRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const ExportTagToBasicFilenamesAndReadUrlsRequestParamsValidator = yup.object().shape(ExportTagToBasicFilenamesAndReadUrlsRequestParamsValidatorShape);
export interface ExportTagToLabelBoxDataRowsRequestParams {
    datasetId: string;
    tagId: string;
}
export function ExportTagToLabelBoxDataRowsRequestParamsSanitizer(json: any): ExportTagToLabelBoxDataRowsRequestParams {
    return {
        'datasetId': json['datasetId'],
        'tagId': json['tagId'],
    };
}
export const ExportTagToLabelBoxDataRowsRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const ExportTagToLabelBoxDataRowsRequestParamsValidator = yup.object().shape(ExportTagToLabelBoxDataRowsRequestParamsValidatorShape);
export interface ExportTagToLabelBoxV4DataRowsRequestParams {
    datasetId: string;
    tagId: string;
}
export function ExportTagToLabelBoxV4DataRowsRequestParamsSanitizer(json: any): ExportTagToLabelBoxV4DataRowsRequestParams {
    return {
        'datasetId': json['datasetId'],
        'tagId': json['tagId'],
    };
}
export const ExportTagToLabelBoxV4DataRowsRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const ExportTagToLabelBoxV4DataRowsRequestParamsValidator = yup.object().shape(ExportTagToLabelBoxV4DataRowsRequestParamsValidatorShape);
export interface ExportTagToLabelStudioTasksRequestParams {
    datasetId: string;
    tagId: string;
}
export function ExportTagToLabelStudioTasksRequestParamsSanitizer(json: any): ExportTagToLabelStudioTasksRequestParams {
    return {
        'datasetId': json['datasetId'],
        'tagId': json['tagId'],
    };
}
export const ExportTagToLabelStudioTasksRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const ExportTagToLabelStudioTasksRequestParamsValidator = yup.object().shape(ExportTagToLabelStudioTasksRequestParamsValidatorShape);
export interface ExportTagToSamaTasksRequestParams {
    datasetId: string;
    tagId: string;
}
export function ExportTagToSamaTasksRequestParamsSanitizer(json: any): ExportTagToSamaTasksRequestParams {
    return {
        'datasetId': json['datasetId'],
        'tagId': json['tagId'],
    };
}
export const ExportTagToSamaTasksRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const ExportTagToSamaTasksRequestParamsValidator = yup.object().shape(ExportTagToSamaTasksRequestParamsValidatorShape);
export interface GetFilenamesByTagIdRequestParams {
    datasetId: string;
    tagId: string;
}
export function GetFilenamesByTagIdRequestParamsSanitizer(json: any): GetFilenamesByTagIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'tagId': json['tagId'],
    };
}
export const GetFilenamesByTagIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetFilenamesByTagIdRequestParamsValidator = yup.object().shape(GetFilenamesByTagIdRequestParamsValidatorShape);
export interface GetTagByTagIdRequestParams {
    datasetId: string;
    tagId: string;
}
export function GetTagByTagIdRequestParamsSanitizer(json: any): GetTagByTagIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'tagId': json['tagId'],
    };
}
export const GetTagByTagIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetTagByTagIdRequestParamsValidator = yup.object().shape(GetTagByTagIdRequestParamsValidatorShape);
export interface GetTagsByDatasetIdRequestParams {
    datasetId: string;
}
export function GetTagsByDatasetIdRequestParamsSanitizer(json: any): GetTagsByDatasetIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const GetTagsByDatasetIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetTagsByDatasetIdRequestParamsValidator = yup.object().shape(GetTagsByDatasetIdRequestParamsValidatorShape);
export interface PerformTagArithmeticsRequestParams {
    datasetId: string;
}
export function PerformTagArithmeticsRequestParamsSanitizer(json: any): PerformTagArithmeticsRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const PerformTagArithmeticsRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const PerformTagArithmeticsRequestParamsValidator = yup.object().shape(PerformTagArithmeticsRequestParamsValidatorShape);
export interface PerformTagArithmeticsBitmaskRequestParams {
    datasetId: string;
}
export function PerformTagArithmeticsBitmaskRequestParamsSanitizer(json: any): PerformTagArithmeticsBitmaskRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const PerformTagArithmeticsBitmaskRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const PerformTagArithmeticsBitmaskRequestParamsValidator = yup.object().shape(PerformTagArithmeticsBitmaskRequestParamsValidatorShape);
export interface UpdateTagByTagIdRequestParams {
    datasetId: string;
    tagId: string;
}
export function UpdateTagByTagIdRequestParamsSanitizer(json: any): UpdateTagByTagIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'tagId': json['tagId'],
    };
}
export const UpdateTagByTagIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const UpdateTagByTagIdRequestParamsValidator = yup.object().shape(UpdateTagByTagIdRequestParamsValidatorShape);
export interface UpsizeTagsByDatasetIdRequestParams {
    datasetId: string;
}
export function UpsizeTagsByDatasetIdRequestParamsSanitizer(json: any): UpsizeTagsByDatasetIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const UpsizeTagsByDatasetIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const UpsizeTagsByDatasetIdRequestParamsValidator = yup.object().shape(UpsizeTagsByDatasetIdRequestParamsValidatorShape);
export interface CreateInitialTagByDatasetIdRequestQuery {
}
export function CreateInitialTagByDatasetIdRequestQuerySanitizer(json: any): CreateInitialTagByDatasetIdRequestQuery {
    return {};
}
export const CreateInitialTagByDatasetIdRequestQueryValidatorShape = {};
export const CreateInitialTagByDatasetIdRequestQueryValidator = yup.object().shape(CreateInitialTagByDatasetIdRequestQueryValidatorShape);
export interface CreateTagByDatasetIdRequestQuery {
}
export function CreateTagByDatasetIdRequestQuerySanitizer(json: any): CreateTagByDatasetIdRequestQuery {
    return {};
}
export const CreateTagByDatasetIdRequestQueryValidatorShape = {};
export const CreateTagByDatasetIdRequestQueryValidator = yup.object().shape(CreateTagByDatasetIdRequestQueryValidatorShape);
export interface DeleteTagByTagIdRequestQuery {
}
export function DeleteTagByTagIdRequestQuerySanitizer(json: any): DeleteTagByTagIdRequestQuery {
    return {};
}
export const DeleteTagByTagIdRequestQueryValidatorShape = {};
export const DeleteTagByTagIdRequestQueryValidator = yup.object().shape(DeleteTagByTagIdRequestQueryValidatorShape);
export interface DownloadZipOfSamplesByTagIdRequestQuery {
}
export function DownloadZipOfSamplesByTagIdRequestQuerySanitizer(json: any): DownloadZipOfSamplesByTagIdRequestQuery {
    return {};
}
export const DownloadZipOfSamplesByTagIdRequestQueryValidatorShape = {};
export const DownloadZipOfSamplesByTagIdRequestQueryValidator = yup.object().shape(DownloadZipOfSamplesByTagIdRequestQueryValidatorShape);
export interface ExportTagToBasicFilenamesRequestQuery {
    expiresIn?: number;
    accessControl?: string;
    fileNameFormat?: models.FileNameFormat;
    includeMetaData?: boolean;
    format?: models.FileOutputFormat;
    previewExample?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
export function ExportTagToBasicFilenamesRequestQuerySanitizer(json: any): ExportTagToBasicFilenamesRequestQuery {
    return {
        'expiresIn': json['expiresIn'],
        'accessControl': json['accessControl'],
        'fileNameFormat': json['fileNameFormat'],
        'includeMetaData': json['includeMetaData'],
        'format': json['format'],
        'previewExample': json['previewExample'],
        'pageSize': json['pageSize'],
        'pageOffset': json['pageOffset'],
    };
}
export const ExportTagToBasicFilenamesRequestQueryValidatorShape = {
    'expiresIn': yup.number().integer(),
    'accessControl': yup.string(),
    'fileNameFormat': models.FileNameFormatValidator.optional().default(undefined),
    'includeMetaData': yup.boolean(),
    'format': models.FileOutputFormatValidator.optional().default(undefined),
    'previewExample': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const ExportTagToBasicFilenamesRequestQueryValidator = yup.object().shape(ExportTagToBasicFilenamesRequestQueryValidatorShape);
export interface ExportTagToBasicFilenamesAndReadUrlsRequestQuery {
    format?: models.FileOutputFormat;
    previewExample?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
export function ExportTagToBasicFilenamesAndReadUrlsRequestQuerySanitizer(json: any): ExportTagToBasicFilenamesAndReadUrlsRequestQuery {
    return {
        'format': json['format'],
        'previewExample': json['previewExample'],
        'pageSize': json['pageSize'],
        'pageOffset': json['pageOffset'],
    };
}
export const ExportTagToBasicFilenamesAndReadUrlsRequestQueryValidatorShape = {
    'format': models.FileOutputFormatValidator.optional().default(undefined),
    'previewExample': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const ExportTagToBasicFilenamesAndReadUrlsRequestQueryValidator = yup.object().shape(ExportTagToBasicFilenamesAndReadUrlsRequestQueryValidatorShape);
export interface ExportTagToLabelBoxDataRowsRequestQuery {
    expiresIn?: number;
    accessControl?: string;
    fileNameFormat?: models.FileNameFormat;
    includeMetaData?: boolean;
    format?: models.FileOutputFormat;
    previewExample?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
export function ExportTagToLabelBoxDataRowsRequestQuerySanitizer(json: any): ExportTagToLabelBoxDataRowsRequestQuery {
    return {
        'expiresIn': json['expiresIn'],
        'accessControl': json['accessControl'],
        'fileNameFormat': json['fileNameFormat'],
        'includeMetaData': json['includeMetaData'],
        'format': json['format'],
        'previewExample': json['previewExample'],
        'pageSize': json['pageSize'],
        'pageOffset': json['pageOffset'],
    };
}
export const ExportTagToLabelBoxDataRowsRequestQueryValidatorShape = {
    'expiresIn': yup.number().integer(),
    'accessControl': yup.string(),
    'fileNameFormat': models.FileNameFormatValidator.optional().default(undefined),
    'includeMetaData': yup.boolean(),
    'format': models.FileOutputFormatValidator.optional().default(undefined),
    'previewExample': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const ExportTagToLabelBoxDataRowsRequestQueryValidator = yup.object().shape(ExportTagToLabelBoxDataRowsRequestQueryValidatorShape);
export interface ExportTagToLabelBoxV4DataRowsRequestQuery {
    expiresIn?: number;
    accessControl?: string;
    fileNameFormat?: models.FileNameFormat;
    includeMetaData?: boolean;
    format?: models.FileOutputFormat;
    previewExample?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
export function ExportTagToLabelBoxV4DataRowsRequestQuerySanitizer(json: any): ExportTagToLabelBoxV4DataRowsRequestQuery {
    return {
        'expiresIn': json['expiresIn'],
        'accessControl': json['accessControl'],
        'fileNameFormat': json['fileNameFormat'],
        'includeMetaData': json['includeMetaData'],
        'format': json['format'],
        'previewExample': json['previewExample'],
        'pageSize': json['pageSize'],
        'pageOffset': json['pageOffset'],
    };
}
export const ExportTagToLabelBoxV4DataRowsRequestQueryValidatorShape = {
    'expiresIn': yup.number().integer(),
    'accessControl': yup.string(),
    'fileNameFormat': models.FileNameFormatValidator.optional().default(undefined),
    'includeMetaData': yup.boolean(),
    'format': models.FileOutputFormatValidator.optional().default(undefined),
    'previewExample': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const ExportTagToLabelBoxV4DataRowsRequestQueryValidator = yup.object().shape(ExportTagToLabelBoxV4DataRowsRequestQueryValidatorShape);
export interface ExportTagToLabelStudioTasksRequestQuery {
    expiresIn?: number;
    accessControl?: string;
    fileNameFormat?: models.FileNameFormat;
    includeMetaData?: boolean;
    format?: models.FileOutputFormat;
    previewExample?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
export function ExportTagToLabelStudioTasksRequestQuerySanitizer(json: any): ExportTagToLabelStudioTasksRequestQuery {
    return {
        'expiresIn': json['expiresIn'],
        'accessControl': json['accessControl'],
        'fileNameFormat': json['fileNameFormat'],
        'includeMetaData': json['includeMetaData'],
        'format': json['format'],
        'previewExample': json['previewExample'],
        'pageSize': json['pageSize'],
        'pageOffset': json['pageOffset'],
    };
}
export const ExportTagToLabelStudioTasksRequestQueryValidatorShape = {
    'expiresIn': yup.number().integer(),
    'accessControl': yup.string(),
    'fileNameFormat': models.FileNameFormatValidator.optional().default(undefined),
    'includeMetaData': yup.boolean(),
    'format': models.FileOutputFormatValidator.optional().default(undefined),
    'previewExample': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const ExportTagToLabelStudioTasksRequestQueryValidator = yup.object().shape(ExportTagToLabelStudioTasksRequestQueryValidatorShape);
export interface ExportTagToSamaTasksRequestQuery {
    expiresIn?: number;
    accessControl?: string;
    fileNameFormat?: models.FileNameFormat;
    includeMetaData?: boolean;
    format?: models.FileOutputFormat;
    previewExample?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
export function ExportTagToSamaTasksRequestQuerySanitizer(json: any): ExportTagToSamaTasksRequestQuery {
    return {
        'expiresIn': json['expiresIn'],
        'accessControl': json['accessControl'],
        'fileNameFormat': json['fileNameFormat'],
        'includeMetaData': json['includeMetaData'],
        'format': json['format'],
        'previewExample': json['previewExample'],
        'pageSize': json['pageSize'],
        'pageOffset': json['pageOffset'],
    };
}
export const ExportTagToSamaTasksRequestQueryValidatorShape = {
    'expiresIn': yup.number().integer(),
    'accessControl': yup.string(),
    'fileNameFormat': models.FileNameFormatValidator.optional().default(undefined),
    'includeMetaData': yup.boolean(),
    'format': models.FileOutputFormatValidator.optional().default(undefined),
    'previewExample': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const ExportTagToSamaTasksRequestQueryValidator = yup.object().shape(ExportTagToSamaTasksRequestQueryValidatorShape);
export interface GetFilenamesByTagIdRequestQuery {
}
export function GetFilenamesByTagIdRequestQuerySanitizer(json: any): GetFilenamesByTagIdRequestQuery {
    return {};
}
export const GetFilenamesByTagIdRequestQueryValidatorShape = {};
export const GetFilenamesByTagIdRequestQueryValidator = yup.object().shape(GetFilenamesByTagIdRequestQueryValidatorShape);
export interface GetTagByTagIdRequestQuery {
}
export function GetTagByTagIdRequestQuerySanitizer(json: any): GetTagByTagIdRequestQuery {
    return {};
}
export const GetTagByTagIdRequestQueryValidatorShape = {};
export const GetTagByTagIdRequestQueryValidator = yup.object().shape(GetTagByTagIdRequestQueryValidatorShape);
export interface GetTagsByDatasetIdRequestQuery {
}
export function GetTagsByDatasetIdRequestQuerySanitizer(json: any): GetTagsByDatasetIdRequestQuery {
    return {};
}
export const GetTagsByDatasetIdRequestQueryValidatorShape = {};
export const GetTagsByDatasetIdRequestQueryValidator = yup.object().shape(GetTagsByDatasetIdRequestQueryValidatorShape);
export interface PerformTagArithmeticsRequestQuery {
}
export function PerformTagArithmeticsRequestQuerySanitizer(json: any): PerformTagArithmeticsRequestQuery {
    return {};
}
export const PerformTagArithmeticsRequestQueryValidatorShape = {};
export const PerformTagArithmeticsRequestQueryValidator = yup.object().shape(PerformTagArithmeticsRequestQueryValidatorShape);
export interface PerformTagArithmeticsBitmaskRequestQuery {
}
export function PerformTagArithmeticsBitmaskRequestQuerySanitizer(json: any): PerformTagArithmeticsBitmaskRequestQuery {
    return {};
}
export const PerformTagArithmeticsBitmaskRequestQueryValidatorShape = {};
export const PerformTagArithmeticsBitmaskRequestQueryValidator = yup.object().shape(PerformTagArithmeticsBitmaskRequestQueryValidatorShape);
export interface UpdateTagByTagIdRequestQuery {
}
export function UpdateTagByTagIdRequestQuerySanitizer(json: any): UpdateTagByTagIdRequestQuery {
    return {};
}
export const UpdateTagByTagIdRequestQueryValidatorShape = {};
export const UpdateTagByTagIdRequestQueryValidator = yup.object().shape(UpdateTagByTagIdRequestQueryValidatorShape);
export interface UpsizeTagsByDatasetIdRequestQuery {
}
export function UpsizeTagsByDatasetIdRequestQuerySanitizer(json: any): UpsizeTagsByDatasetIdRequestQuery {
    return {};
}
export const UpsizeTagsByDatasetIdRequestQueryValidatorShape = {};
export const UpsizeTagsByDatasetIdRequestQueryValidator = yup.object().shape(UpsizeTagsByDatasetIdRequestQueryValidatorShape);
export type CreateInitialTagByDatasetIdRequestBody = InitialTagCreateRequest;
export const CreateInitialTagByDatasetIdRequestBodySanitizer = InitialTagCreateRequestFromJSON;
export const CreateInitialTagByDatasetIdRequestBodyValidator = InitialTagCreateRequestValidator;
export const CreateInitialTagByDatasetIdTypiaAssertEquals = (input: any) => {
    return ((input: any): CreateInitialTagByDatasetIdRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is CreateInitialTagByDatasetIdRequestBody => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.name || "string" === typeof input.name && 3 <= input.name.length && $is_custom("pattern_InitialTagCreateRequest_name", "string", "", input.name)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator) && ("full" === input.imgType || "thumbnail" === input.imgType || "meta" === input.imgType) && (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_InitialTagCreateRequest_runId", "string", "", input.runId)) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["name", "creator", "imgType", "runId"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateInitialTagByDatasetIdRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.name || "string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_InitialTagCreateRequest_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_InitialTagCreateRequest_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "(string | undefined)",
                    value: input.name
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && ("full" === input.imgType || "thumbnail" === input.imgType || "meta" === input.imgType || $guard(_exceptionable, {
                    path: _path + ".imgType",
                    expected: "(\"full\" | \"meta\" | \"thumbnail\")",
                    value: input.imgType
                })) && (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_InitialTagCreateRequest_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_InitialTagCreateRequest_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["name", "creator", "imgType", "runId"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "InitialTagCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "InitialTagCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const CreateInitialTagByDatasetIdTypiaAssertStringify = (input: CreateInitialTagByDatasetIdRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): CreateInitialTagByDatasetIdRequestBody => {
        const __is = (input: any): input is CreateInitialTagByDatasetIdRequestBody => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => (undefined === input.name || "string" === typeof input.name && 3 <= input.name.length && $is_custom("pattern_InitialTagCreateRequest_name", "string", "", input.name)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator) && ("full" === input.imgType || "thumbnail" === input.imgType || "meta" === input.imgType) && (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_InitialTagCreateRequest_runId", "string", "", input.runId));
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateInitialTagByDatasetIdRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.name || "string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_InitialTagCreateRequest_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_InitialTagCreateRequest_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "(string | undefined)",
                    value: input.name
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && ("full" === input.imgType || "thumbnail" === input.imgType || "meta" === input.imgType || $guard(_exceptionable, {
                    path: _path + ".imgType",
                    expected: "(\"full\" | \"meta\" | \"thumbnail\")",
                    value: input.imgType
                })) && (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_InitialTagCreateRequest_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_InitialTagCreateRequest_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "InitialTagCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "InitialTagCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: CreateInitialTagByDatasetIdRequestBody): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.name ? "" : `"name":${undefined !== input.name ? $string(input.name) : undefined},`}${undefined === input.creator ? "" : `"creator":${undefined !== input.creator ? (() => {
            if ("string" === typeof input.creator)
                return $string(input.creator);
            if ("string" === typeof input.creator)
                return "\"" + input.creator + "\"";
            $throws({
                expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                value: input.creator
            });
        })() : undefined},`}${undefined === input.runId ? "" : `"runId":${undefined !== input.runId ? $string(input.runId) : undefined},`}"imgType":${(() => {
            if ("string" === typeof input.imgType)
                return $string(input.imgType);
            if ("string" === typeof input.imgType)
                return "\"" + input.imgType + "\"";
            $throws({
                expected: "(\"full\" | \"meta\" | \"thumbnail\")",
                value: input.imgType
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export type CreateTagByDatasetIdRequestBody = TagCreateRequest;
export const CreateTagByDatasetIdRequestBodySanitizer = TagCreateRequestFromJSON;
export const CreateTagByDatasetIdRequestBodyValidator = TagCreateRequestValidator;
export const CreateTagByDatasetIdTypiaAssertEquals = (input: any) => {
    return ((input: any): CreateTagByDatasetIdRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is CreateTagByDatasetIdRequestBody => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.name && 3 <= input.name.length && $is_custom("pattern_TagCreateRequest_name", "string", "", input.name) && ("string" === typeof input.prevTagId && $is_custom("pattern_TagCreateRequest_prevTagId", "string", "", input.prevTagId)) && (undefined === input.queryTagId || "string" === typeof input.queryTagId && $is_custom("pattern_TagCreateRequest_queryTagId", "string", "", input.queryTagId)) && (undefined === input.preselectedTagId || "string" === typeof input.preselectedTagId && $is_custom("pattern_TagCreateRequest_preselectedTagId", "string", "", input.preselectedTagId)) && ("string" === typeof input.bitMaskData && $is_custom("pattern_TagCreateRequest_bitMaskData", "string", "", input.bitMaskData)) && ("number" === typeof input.totSize && Math.floor(input.totSize) === input.totSize && (-2147483648 <= input.totSize && input.totSize <= 2147483647)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator) && (undefined === input.changes || "object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io1(input.changes, true && _exceptionable)) && (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagCreateRequest_runId", "string", "", input.runId)) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["name", "prevTagId", "queryTagId", "preselectedTagId", "bitMaskData", "totSize", "creator", "changes", "runId"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.initial || "object" === typeof input.initial && null !== input.initial && false === Array.isArray(input.initial) && $io2(input.initial, true && _exceptionable)) && (undefined === input.rename || "object" === typeof input.rename && null !== input.rename && $io3(input.rename, true && _exceptionable)) && (undefined === input.upsize || "object" === typeof input.upsize && null !== input.upsize && $io4(input.upsize, true && _exceptionable)) && (undefined === input.arithmetics || "object" === typeof input.arithmetics && null !== input.arithmetics && $io5(input.arithmetics, true && _exceptionable)) && (undefined === input.metadata || "object" === typeof input.metadata && null !== input.metadata && $io6(input.metadata, true && _exceptionable)) && (undefined === input.samples || "object" === typeof input.samples && null !== input.samples && $io8(input.samples, true && _exceptionable)) && (undefined === input.scatterplot || "object" === typeof input.scatterplot && null !== input.scatterplot && $io9(input.scatterplot, true && _exceptionable)) && (undefined === input.sampler || "object" === typeof input.sampler && null !== input.sampler && $io10(input.sampler, true && _exceptionable)) && Object.keys(input).every((key: any) => {
                if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagChangeDataInitial_runId", "string", "", input.runId)) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["runId"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io3 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.from && "string" === typeof input.to && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["from", "to"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io4 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagChangeDataUpsize_runId", "string", "", input.runId)) && "number" === typeof input.from && "number" === typeof input.to && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["runId", "from", "to"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io5 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.operation && "string" === typeof input.tag1 && "string" === typeof input.tag2 && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["operation", "tag1", "tag2"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io6 = (input: any, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed) && ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io7(input.changes, true && _exceptionable)) && (5 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["method", "count", "added", "removed", "changes"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io7 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io8 = (input: any, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["method", "count", "added", "removed"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io9 = (input: any, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && (undefined === input.view || "string" === typeof input.view) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["method", "view", "count", "added", "removed"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io10 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.method && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["method"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateTagByDatasetIdRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_TagCreateRequest_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_TagCreateRequest_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("string" === typeof input.prevTagId && ($is_custom("pattern_TagCreateRequest_prevTagId", "string", "", input.prevTagId) || $guard(_exceptionable, {
                    path: _path + ".prevTagId",
                    expected: "string (@pattern_TagCreateRequest_prevTagId)",
                    value: input.prevTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".prevTagId",
                    expected: "string",
                    value: input.prevTagId
                })) && (undefined === input.queryTagId || "string" === typeof input.queryTagId && ($is_custom("pattern_TagCreateRequest_queryTagId", "string", "", input.queryTagId) || $guard(_exceptionable, {
                    path: _path + ".queryTagId",
                    expected: "string (@pattern_TagCreateRequest_queryTagId)",
                    value: input.queryTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".queryTagId",
                    expected: "(string | undefined)",
                    value: input.queryTagId
                })) && (undefined === input.preselectedTagId || "string" === typeof input.preselectedTagId && ($is_custom("pattern_TagCreateRequest_preselectedTagId", "string", "", input.preselectedTagId) || $guard(_exceptionable, {
                    path: _path + ".preselectedTagId",
                    expected: "string (@pattern_TagCreateRequest_preselectedTagId)",
                    value: input.preselectedTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".preselectedTagId",
                    expected: "(string | undefined)",
                    value: input.preselectedTagId
                })) && ("string" === typeof input.bitMaskData && ($is_custom("pattern_TagCreateRequest_bitMaskData", "string", "", input.bitMaskData) || $guard(_exceptionable, {
                    path: _path + ".bitMaskData",
                    expected: "string (@pattern_TagCreateRequest_bitMaskData)",
                    value: input.bitMaskData
                })) || $guard(_exceptionable, {
                    path: _path + ".bitMaskData",
                    expected: "string",
                    value: input.bitMaskData
                })) && ("number" === typeof input.totSize && (Math.floor(input.totSize) === input.totSize || $guard(_exceptionable, {
                    path: _path + ".totSize",
                    expected: "number (@type int)",
                    value: input.totSize
                })) && (-2147483648 <= input.totSize && input.totSize <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".totSize",
                    expected: "number (@type int)",
                    value: input.totSize
                })) || $guard(_exceptionable, {
                    path: _path + ".totSize",
                    expected: "number",
                    value: input.totSize
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (undefined === input.changes || ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "(TagChangeData | undefined)",
                    value: input.changes
                })) && $ao1(input.changes, _path + ".changes", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "(TagChangeData | undefined)",
                    value: input.changes
                })) && (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagCreateRequest_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagCreateRequest_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["name", "prevTagId", "queryTagId", "preselectedTagId", "bitMaskData", "totSize", "creator", "changes", "runId"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.initial || ("object" === typeof input.initial && null !== input.initial && false === Array.isArray(input.initial) || $guard(_exceptionable, {
                    path: _path + ".initial",
                    expected: "(TagChangeDataInitial | undefined)",
                    value: input.initial
                })) && $ao2(input.initial, _path + ".initial", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".initial",
                    expected: "(TagChangeDataInitial | undefined)",
                    value: input.initial
                })) && (undefined === input.rename || ("object" === typeof input.rename && null !== input.rename || $guard(_exceptionable, {
                    path: _path + ".rename",
                    expected: "(TagChangeDataRename | undefined)",
                    value: input.rename
                })) && $ao3(input.rename, _path + ".rename", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".rename",
                    expected: "(TagChangeDataRename | undefined)",
                    value: input.rename
                })) && (undefined === input.upsize || ("object" === typeof input.upsize && null !== input.upsize || $guard(_exceptionable, {
                    path: _path + ".upsize",
                    expected: "(TagChangeDataUpsize | undefined)",
                    value: input.upsize
                })) && $ao4(input.upsize, _path + ".upsize", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".upsize",
                    expected: "(TagChangeDataUpsize | undefined)",
                    value: input.upsize
                })) && (undefined === input.arithmetics || ("object" === typeof input.arithmetics && null !== input.arithmetics || $guard(_exceptionable, {
                    path: _path + ".arithmetics",
                    expected: "(TagChangeDataArithmetics | undefined)",
                    value: input.arithmetics
                })) && $ao5(input.arithmetics, _path + ".arithmetics", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".arithmetics",
                    expected: "(TagChangeDataArithmetics | undefined)",
                    value: input.arithmetics
                })) && (undefined === input.metadata || ("object" === typeof input.metadata && null !== input.metadata || $guard(_exceptionable, {
                    path: _path + ".metadata",
                    expected: "(TagChangeDataMetadata | undefined)",
                    value: input.metadata
                })) && $ao6(input.metadata, _path + ".metadata", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".metadata",
                    expected: "(TagChangeDataMetadata | undefined)",
                    value: input.metadata
                })) && (undefined === input.samples || ("object" === typeof input.samples && null !== input.samples || $guard(_exceptionable, {
                    path: _path + ".samples",
                    expected: "(TagChangeDataSamples | undefined)",
                    value: input.samples
                })) && $ao8(input.samples, _path + ".samples", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".samples",
                    expected: "(TagChangeDataSamples | undefined)",
                    value: input.samples
                })) && (undefined === input.scatterplot || ("object" === typeof input.scatterplot && null !== input.scatterplot || $guard(_exceptionable, {
                    path: _path + ".scatterplot",
                    expected: "(TagChangeDataScatterplot | undefined)",
                    value: input.scatterplot
                })) && $ao9(input.scatterplot, _path + ".scatterplot", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".scatterplot",
                    expected: "(TagChangeDataScatterplot | undefined)",
                    value: input.scatterplot
                })) && (undefined === input.sampler || ("object" === typeof input.sampler && null !== input.sampler || $guard(_exceptionable, {
                    path: _path + ".sampler",
                    expected: "(TagChangeDataSampler | undefined)",
                    value: input.sampler
                })) && $ao10(input.sampler, _path + ".sampler", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".sampler",
                    expected: "(TagChangeDataSampler | undefined)",
                    value: input.sampler
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagChangeDataInitial_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagChangeDataInitial_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["runId"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.from || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "string",
                    value: input.from
                })) && ("string" === typeof input.to || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "string",
                    value: input.to
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["from", "to"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagChangeDataUpsize_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagChangeDataUpsize_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && ("number" === typeof input.from || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "number",
                    value: input.from
                })) && ("number" === typeof input.to || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "number",
                    value: input.to
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["runId", "from", "to"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "string",
                    value: input.operation
                })) && ("string" === typeof input.tag1 || $guard(_exceptionable, {
                    path: _path + ".tag1",
                    expected: "string",
                    value: input.tag1
                })) && ("string" === typeof input.tag2 || $guard(_exceptionable, {
                    path: _path + ".tag2",
                    expected: "string",
                    value: input.tag2
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["operation", "tag1", "tag2"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && ("number" === typeof input.count && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                })) && (("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                })) && $ao7(input.changes, _path + ".changes", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["method", "count", "added", "removed", "changes"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && ("number" === typeof input.count && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["method", "count", "added", "removed"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao9 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && (undefined === input.view || "string" === typeof input.view || $guard(_exceptionable, {
                    path: _path + ".view",
                    expected: "(string | undefined)",
                    value: input.view
                })) && ("number" === typeof input.count && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["method", "view", "count", "added", "removed"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao10 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "string",
                    value: input.method
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["method"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const CreateTagByDatasetIdTypiaAssertStringify = (input: CreateTagByDatasetIdRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): CreateTagByDatasetIdRequestBody => {
        const __is = (input: any): input is CreateTagByDatasetIdRequestBody => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "string" === typeof input.name && 3 <= input.name.length && $is_custom("pattern_TagCreateRequest_name", "string", "", input.name) && ("string" === typeof input.prevTagId && $is_custom("pattern_TagCreateRequest_prevTagId", "string", "", input.prevTagId)) && (undefined === input.queryTagId || "string" === typeof input.queryTagId && $is_custom("pattern_TagCreateRequest_queryTagId", "string", "", input.queryTagId)) && (undefined === input.preselectedTagId || "string" === typeof input.preselectedTagId && $is_custom("pattern_TagCreateRequest_preselectedTagId", "string", "", input.preselectedTagId)) && ("string" === typeof input.bitMaskData && $is_custom("pattern_TagCreateRequest_bitMaskData", "string", "", input.bitMaskData)) && ("number" === typeof input.totSize && !Number.isNaN(input.totSize) && Math.floor(input.totSize) === input.totSize && (-2147483648 <= input.totSize && input.totSize <= 2147483647)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator) && (undefined === input.changes || "object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io1(input.changes)) && (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagCreateRequest_runId", "string", "", input.runId));
            const $io1 = (input: any): boolean => (undefined === input.initial || "object" === typeof input.initial && null !== input.initial && false === Array.isArray(input.initial) && $io2(input.initial)) && (undefined === input.rename || "object" === typeof input.rename && null !== input.rename && $io3(input.rename)) && (undefined === input.upsize || "object" === typeof input.upsize && null !== input.upsize && $io4(input.upsize)) && (undefined === input.arithmetics || "object" === typeof input.arithmetics && null !== input.arithmetics && $io5(input.arithmetics)) && (undefined === input.metadata || "object" === typeof input.metadata && null !== input.metadata && $io6(input.metadata)) && (undefined === input.samples || "object" === typeof input.samples && null !== input.samples && $io8(input.samples)) && (undefined === input.scatterplot || "object" === typeof input.scatterplot && null !== input.scatterplot && $io9(input.scatterplot)) && (undefined === input.sampler || "object" === typeof input.sampler && null !== input.sampler && $io10(input.sampler)) && Object.keys(input).every((key: any) => {
                if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io2 = (input: any): boolean => undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagChangeDataInitial_runId", "string", "", input.runId);
            const $io3 = (input: any): boolean => "string" === typeof input.from && "string" === typeof input.to;
            const $io4 = (input: any): boolean => (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagChangeDataUpsize_runId", "string", "", input.runId)) && ("number" === typeof input.from && !Number.isNaN(input.from)) && ("number" === typeof input.to && !Number.isNaN(input.to));
            const $io5 = (input: any): boolean => "string" === typeof input.operation && "string" === typeof input.tag1 && "string" === typeof input.tag2;
            const $io6 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && !Number.isNaN(input.count) && 0 <= input.count) && ("number" === typeof input.added && !Number.isNaN(input.added) && 0 <= input.added) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && 0 <= input.removed) && ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io7(input.changes));
            const $io7 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io8 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && !Number.isNaN(input.count) && 0 <= input.count) && ("number" === typeof input.added && !Number.isNaN(input.added) && 0 <= input.added) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && 0 <= input.removed);
            const $io9 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && (undefined === input.view || "string" === typeof input.view) && ("number" === typeof input.count && !Number.isNaN(input.count) && 0 <= input.count) && ("number" === typeof input.added && !Number.isNaN(input.added) && 0 <= input.added) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && 0 <= input.removed);
            const $io10 = (input: any): boolean => "string" === typeof input.method;
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateTagByDatasetIdRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_TagCreateRequest_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_TagCreateRequest_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("string" === typeof input.prevTagId && ($is_custom("pattern_TagCreateRequest_prevTagId", "string", "", input.prevTagId) || $guard(_exceptionable, {
                    path: _path + ".prevTagId",
                    expected: "string (@pattern_TagCreateRequest_prevTagId)",
                    value: input.prevTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".prevTagId",
                    expected: "string",
                    value: input.prevTagId
                })) && (undefined === input.queryTagId || "string" === typeof input.queryTagId && ($is_custom("pattern_TagCreateRequest_queryTagId", "string", "", input.queryTagId) || $guard(_exceptionable, {
                    path: _path + ".queryTagId",
                    expected: "string (@pattern_TagCreateRequest_queryTagId)",
                    value: input.queryTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".queryTagId",
                    expected: "(string | undefined)",
                    value: input.queryTagId
                })) && (undefined === input.preselectedTagId || "string" === typeof input.preselectedTagId && ($is_custom("pattern_TagCreateRequest_preselectedTagId", "string", "", input.preselectedTagId) || $guard(_exceptionable, {
                    path: _path + ".preselectedTagId",
                    expected: "string (@pattern_TagCreateRequest_preselectedTagId)",
                    value: input.preselectedTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".preselectedTagId",
                    expected: "(string | undefined)",
                    value: input.preselectedTagId
                })) && ("string" === typeof input.bitMaskData && ($is_custom("pattern_TagCreateRequest_bitMaskData", "string", "", input.bitMaskData) || $guard(_exceptionable, {
                    path: _path + ".bitMaskData",
                    expected: "string (@pattern_TagCreateRequest_bitMaskData)",
                    value: input.bitMaskData
                })) || $guard(_exceptionable, {
                    path: _path + ".bitMaskData",
                    expected: "string",
                    value: input.bitMaskData
                })) && ("number" === typeof input.totSize && !Number.isNaN(input.totSize) && (Math.floor(input.totSize) === input.totSize || $guard(_exceptionable, {
                    path: _path + ".totSize",
                    expected: "number (@type int)",
                    value: input.totSize
                })) && (-2147483648 <= input.totSize && input.totSize <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".totSize",
                    expected: "number (@type int)",
                    value: input.totSize
                })) || $guard(_exceptionable, {
                    path: _path + ".totSize",
                    expected: "number",
                    value: input.totSize
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (undefined === input.changes || ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "(TagChangeData | undefined)",
                    value: input.changes
                })) && $ao1(input.changes, _path + ".changes", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "(TagChangeData | undefined)",
                    value: input.changes
                })) && (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagCreateRequest_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagCreateRequest_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.initial || ("object" === typeof input.initial && null !== input.initial && false === Array.isArray(input.initial) || $guard(_exceptionable, {
                    path: _path + ".initial",
                    expected: "(TagChangeDataInitial | undefined)",
                    value: input.initial
                })) && $ao2(input.initial, _path + ".initial", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".initial",
                    expected: "(TagChangeDataInitial | undefined)",
                    value: input.initial
                })) && (undefined === input.rename || ("object" === typeof input.rename && null !== input.rename || $guard(_exceptionable, {
                    path: _path + ".rename",
                    expected: "(TagChangeDataRename | undefined)",
                    value: input.rename
                })) && $ao3(input.rename, _path + ".rename", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".rename",
                    expected: "(TagChangeDataRename | undefined)",
                    value: input.rename
                })) && (undefined === input.upsize || ("object" === typeof input.upsize && null !== input.upsize || $guard(_exceptionable, {
                    path: _path + ".upsize",
                    expected: "(TagChangeDataUpsize | undefined)",
                    value: input.upsize
                })) && $ao4(input.upsize, _path + ".upsize", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".upsize",
                    expected: "(TagChangeDataUpsize | undefined)",
                    value: input.upsize
                })) && (undefined === input.arithmetics || ("object" === typeof input.arithmetics && null !== input.arithmetics || $guard(_exceptionable, {
                    path: _path + ".arithmetics",
                    expected: "(TagChangeDataArithmetics | undefined)",
                    value: input.arithmetics
                })) && $ao5(input.arithmetics, _path + ".arithmetics", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".arithmetics",
                    expected: "(TagChangeDataArithmetics | undefined)",
                    value: input.arithmetics
                })) && (undefined === input.metadata || ("object" === typeof input.metadata && null !== input.metadata || $guard(_exceptionable, {
                    path: _path + ".metadata",
                    expected: "(TagChangeDataMetadata | undefined)",
                    value: input.metadata
                })) && $ao6(input.metadata, _path + ".metadata", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".metadata",
                    expected: "(TagChangeDataMetadata | undefined)",
                    value: input.metadata
                })) && (undefined === input.samples || ("object" === typeof input.samples && null !== input.samples || $guard(_exceptionable, {
                    path: _path + ".samples",
                    expected: "(TagChangeDataSamples | undefined)",
                    value: input.samples
                })) && $ao8(input.samples, _path + ".samples", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".samples",
                    expected: "(TagChangeDataSamples | undefined)",
                    value: input.samples
                })) && (undefined === input.scatterplot || ("object" === typeof input.scatterplot && null !== input.scatterplot || $guard(_exceptionable, {
                    path: _path + ".scatterplot",
                    expected: "(TagChangeDataScatterplot | undefined)",
                    value: input.scatterplot
                })) && $ao9(input.scatterplot, _path + ".scatterplot", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".scatterplot",
                    expected: "(TagChangeDataScatterplot | undefined)",
                    value: input.scatterplot
                })) && (undefined === input.sampler || ("object" === typeof input.sampler && null !== input.sampler || $guard(_exceptionable, {
                    path: _path + ".sampler",
                    expected: "(TagChangeDataSampler | undefined)",
                    value: input.sampler
                })) && $ao10(input.sampler, _path + ".sampler", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".sampler",
                    expected: "(TagChangeDataSampler | undefined)",
                    value: input.sampler
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagChangeDataInitial_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagChangeDataInitial_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                });
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.from || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "string",
                    value: input.from
                })) && ("string" === typeof input.to || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "string",
                    value: input.to
                }));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagChangeDataUpsize_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagChangeDataUpsize_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && ("number" === typeof input.from && !Number.isNaN(input.from) || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "number",
                    value: input.from
                })) && ("number" === typeof input.to && !Number.isNaN(input.to) || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "number",
                    value: input.to
                }));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "string",
                    value: input.operation
                })) && ("string" === typeof input.tag1 || $guard(_exceptionable, {
                    path: _path + ".tag1",
                    expected: "string",
                    value: input.tag1
                })) && ("string" === typeof input.tag2 || $guard(_exceptionable, {
                    path: _path + ".tag2",
                    expected: "string",
                    value: input.tag2
                }));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && ("number" === typeof input.count && !Number.isNaN(input.count) && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && !Number.isNaN(input.added) && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                })) && (("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                })) && $ao7(input.changes, _path + ".changes", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                }));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && ("number" === typeof input.count && !Number.isNaN(input.count) && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && !Number.isNaN(input.added) && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                }));
                const $ao9 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && (undefined === input.view || "string" === typeof input.view || $guard(_exceptionable, {
                    path: _path + ".view",
                    expected: "(string | undefined)",
                    value: input.view
                })) && ("number" === typeof input.count && !Number.isNaN(input.count) && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && !Number.isNaN(input.added) && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                }));
                const $ao10 = (input: any, _path: string, _exceptionable: boolean = true): boolean => "string" === typeof input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "string",
                    value: input.method
                });
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: CreateTagByDatasetIdRequestBody): string => {
        const $io1 = (input: any): boolean => (undefined === input.initial || "object" === typeof input.initial && null !== input.initial && false === Array.isArray(input.initial) && $io2(input.initial)) && (undefined === input.rename || "object" === typeof input.rename && null !== input.rename && $io3(input.rename)) && (undefined === input.upsize || "object" === typeof input.upsize && null !== input.upsize && $io4(input.upsize)) && (undefined === input.arithmetics || "object" === typeof input.arithmetics && null !== input.arithmetics && $io5(input.arithmetics)) && (undefined === input.metadata || "object" === typeof input.metadata && null !== input.metadata && $io6(input.metadata)) && (undefined === input.samples || "object" === typeof input.samples && null !== input.samples && $io8(input.samples)) && (undefined === input.scatterplot || "object" === typeof input.scatterplot && null !== input.scatterplot && $io9(input.scatterplot)) && (undefined === input.sampler || "object" === typeof input.sampler && null !== input.sampler && $io10(input.sampler)) && Object.keys(input).every((key: any) => {
            if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some((prop: any) => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io2 = (input: any): boolean => undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagChangeDataInitial_runId", "string", "", input.runId);
        const $io3 = (input: any): boolean => "string" === typeof input.from && "string" === typeof input.to;
        const $io4 = (input: any): boolean => (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagChangeDataUpsize_runId", "string", "", input.runId)) && "number" === typeof input.from && "number" === typeof input.to;
        const $io5 = (input: any): boolean => "string" === typeof input.operation && "string" === typeof input.tag1 && "string" === typeof input.tag2;
        const $io6 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed) && ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io7(input.changes));
        const $io7 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io8 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed);
        const $io9 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && (undefined === input.view || "string" === typeof input.view) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed);
        const $io10 = (input: any): boolean => "string" === typeof input.method;
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.queryTagId ? "" : `"queryTagId":${undefined !== input.queryTagId ? $string(input.queryTagId) : undefined},`}${undefined === input.preselectedTagId ? "" : `"preselectedTagId":${undefined !== input.preselectedTagId ? $string(input.preselectedTagId) : undefined},`}${undefined === input.creator ? "" : `"creator":${undefined !== input.creator ? (() => {
            if ("string" === typeof input.creator)
                return $string(input.creator);
            if ("string" === typeof input.creator)
                return "\"" + input.creator + "\"";
            $throws({
                expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                value: input.creator
            });
        })() : undefined},`}${undefined === input.changes ? "" : `"changes":${undefined !== input.changes ? $so1(input.changes) : undefined},`}${undefined === input.runId ? "" : `"runId":${undefined !== input.runId ? $string(input.runId) : undefined},`}"name":${$string(input.name)},"prevTagId":${$string(input.prevTagId)},"bitMaskData":${$string(input.bitMaskData)},"totSize":${input.totSize}}`;
        const $so1 = (input: any): any => `{${$tail(`${undefined === input.initial ? "" : `"initial":${undefined !== input.initial ? $so2(input.initial) : undefined},`}${undefined === input.rename ? "" : `"rename":${undefined !== input.rename ? `{"from":${$string((input.rename as any).from)},"to":${$string((input.rename as any).to)}}` : undefined},`}${undefined === input.upsize ? "" : `"upsize":${undefined !== input.upsize ? $so4(input.upsize) : undefined},`}${undefined === input.arithmetics ? "" : `"arithmetics":${undefined !== input.arithmetics ? `{"operation":${$string((input.arithmetics as any).operation)},"tag1":${$string((input.arithmetics as any).tag1)},"tag2":${$string((input.arithmetics as any).tag2)}}` : undefined},`}${undefined === input.metadata ? "" : `"metadata":${undefined !== input.metadata ? $so6(input.metadata) : undefined},`}${undefined === input.samples ? "" : `"samples":${undefined !== input.samples ? $so8(input.samples) : undefined},`}${undefined === input.scatterplot ? "" : `"scatterplot":${undefined !== input.scatterplot ? $so9(input.scatterplot) : undefined},`}${undefined === input.sampler ? "" : `"sampler":${undefined !== input.sampler ? `{"method":${$string((input.sampler as any).method)}}` : undefined},`}${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some((regular: any) => regular === key))
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        const $so2 = (input: any): any => `{${$tail(`${undefined === input.runId ? "" : `"runId":${undefined !== input.runId ? $string(input.runId) : undefined}`}`)}}`;
        const $so4 = (input: any): any => `{${undefined === input.runId ? "" : `"runId":${undefined !== input.runId ? $string(input.runId) : undefined},`}"from":${input.from},"to":${input.to}}`;
        const $so6 = (input: any): any => `{"method":${(() => {
            if ("string" === typeof input.method)
                return $string(input.method);
            if ("string" === typeof input.method)
                return "\"" + input.method + "\"";
            $throws({
                expected: "(\"added\" | \"removed\" | \"selected\")",
                value: input.method
            });
        })()},"count":${input.count},"added":${input.added},"removed":${input.removed},"changes":${$so7(input.changes)}}`;
        const $so7 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so8 = (input: any): any => `{"method":${(() => {
            if ("string" === typeof input.method)
                return $string(input.method);
            if ("string" === typeof input.method)
                return "\"" + input.method + "\"";
            $throws({
                expected: "(\"added\" | \"removed\" | \"selected\")",
                value: input.method
            });
        })()},"count":${input.count},"added":${input.added},"removed":${input.removed}}`;
        const $so9 = (input: any): any => `{${undefined === input.view ? "" : `"view":${undefined !== input.view ? $string(input.view) : undefined},`}"method":${(() => {
            if ("string" === typeof input.method)
                return $string(input.method);
            if ("string" === typeof input.method)
                return "\"" + input.method + "\"";
            $throws({
                expected: "(\"added\" | \"removed\" | \"selected\")",
                value: input.method
            });
        })()},"count":${input.count},"added":${input.added},"removed":${input.removed}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export type PerformTagArithmeticsRequestBody = TagArithmeticsRequest;
export const PerformTagArithmeticsRequestBodySanitizer = TagArithmeticsRequestFromJSON;
export const PerformTagArithmeticsRequestBodyValidator = TagArithmeticsRequestValidator;
export const PerformTagArithmeticsTypiaAssertEquals = (input: any) => {
    return ((input: any): PerformTagArithmeticsRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is PerformTagArithmeticsRequestBody => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.tagId1 && $is_custom("pattern_TagArithmeticsRequest_tagId1", "string", "", input.tagId1) && ("string" === typeof input.tagId2 && $is_custom("pattern_TagArithmeticsRequest_tagId2", "string", "", input.tagId2)) && ("UNION" === input.operation || "INTERSECTION" === input.operation || "DIFFERENCE" === input.operation) && (undefined === input.newTagName || "string" === typeof input.newTagName && 3 <= input.newTagName.length && $is_custom("pattern_TagArithmeticsRequest_newTagName", "string", "", input.newTagName)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator) && (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagArithmeticsRequest_runId", "string", "", input.runId)) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["tagId1", "tagId2", "operation", "newTagName", "creator", "runId"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PerformTagArithmeticsRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.tagId1 && ($is_custom("pattern_TagArithmeticsRequest_tagId1", "string", "", input.tagId1) || $guard(_exceptionable, {
                    path: _path + ".tagId1",
                    expected: "string (@pattern_TagArithmeticsRequest_tagId1)",
                    value: input.tagId1
                })) || $guard(_exceptionable, {
                    path: _path + ".tagId1",
                    expected: "string",
                    value: input.tagId1
                })) && ("string" === typeof input.tagId2 && ($is_custom("pattern_TagArithmeticsRequest_tagId2", "string", "", input.tagId2) || $guard(_exceptionable, {
                    path: _path + ".tagId2",
                    expected: "string (@pattern_TagArithmeticsRequest_tagId2)",
                    value: input.tagId2
                })) || $guard(_exceptionable, {
                    path: _path + ".tagId2",
                    expected: "string",
                    value: input.tagId2
                })) && ("UNION" === input.operation || "INTERSECTION" === input.operation || "DIFFERENCE" === input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "(\"DIFFERENCE\" | \"INTERSECTION\" | \"UNION\")",
                    value: input.operation
                })) && (undefined === input.newTagName || "string" === typeof input.newTagName && (3 <= input.newTagName.length || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@minLength 3)",
                    value: input.newTagName
                })) && ($is_custom("pattern_TagArithmeticsRequest_newTagName", "string", "", input.newTagName) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@pattern_TagArithmeticsRequest_newTagName)",
                    value: input.newTagName
                })) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "(string | undefined)",
                    value: input.newTagName
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagArithmeticsRequest_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagArithmeticsRequest_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["tagId1", "tagId2", "operation", "newTagName", "creator", "runId"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagArithmeticsRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagArithmeticsRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const PerformTagArithmeticsTypiaAssertStringify = (input: PerformTagArithmeticsRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): PerformTagArithmeticsRequestBody => {
        const __is = (input: any): input is PerformTagArithmeticsRequestBody => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.tagId1 && $is_custom("pattern_TagArithmeticsRequest_tagId1", "string", "", input.tagId1) && ("string" === typeof input.tagId2 && $is_custom("pattern_TagArithmeticsRequest_tagId2", "string", "", input.tagId2)) && ("UNION" === input.operation || "INTERSECTION" === input.operation || "DIFFERENCE" === input.operation) && (undefined === input.newTagName || "string" === typeof input.newTagName && 3 <= input.newTagName.length && $is_custom("pattern_TagArithmeticsRequest_newTagName", "string", "", input.newTagName)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator) && (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagArithmeticsRequest_runId", "string", "", input.runId));
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PerformTagArithmeticsRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.tagId1 && ($is_custom("pattern_TagArithmeticsRequest_tagId1", "string", "", input.tagId1) || $guard(_exceptionable, {
                    path: _path + ".tagId1",
                    expected: "string (@pattern_TagArithmeticsRequest_tagId1)",
                    value: input.tagId1
                })) || $guard(_exceptionable, {
                    path: _path + ".tagId1",
                    expected: "string",
                    value: input.tagId1
                })) && ("string" === typeof input.tagId2 && ($is_custom("pattern_TagArithmeticsRequest_tagId2", "string", "", input.tagId2) || $guard(_exceptionable, {
                    path: _path + ".tagId2",
                    expected: "string (@pattern_TagArithmeticsRequest_tagId2)",
                    value: input.tagId2
                })) || $guard(_exceptionable, {
                    path: _path + ".tagId2",
                    expected: "string",
                    value: input.tagId2
                })) && ("UNION" === input.operation || "INTERSECTION" === input.operation || "DIFFERENCE" === input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "(\"DIFFERENCE\" | \"INTERSECTION\" | \"UNION\")",
                    value: input.operation
                })) && (undefined === input.newTagName || "string" === typeof input.newTagName && (3 <= input.newTagName.length || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@minLength 3)",
                    value: input.newTagName
                })) && ($is_custom("pattern_TagArithmeticsRequest_newTagName", "string", "", input.newTagName) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@pattern_TagArithmeticsRequest_newTagName)",
                    value: input.newTagName
                })) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "(string | undefined)",
                    value: input.newTagName
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagArithmeticsRequest_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagArithmeticsRequest_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagArithmeticsRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagArithmeticsRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: PerformTagArithmeticsRequestBody): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.newTagName ? "" : `"newTagName":${undefined !== input.newTagName ? $string(input.newTagName) : undefined},`}${undefined === input.creator ? "" : `"creator":${undefined !== input.creator ? (() => {
            if ("string" === typeof input.creator)
                return $string(input.creator);
            if ("string" === typeof input.creator)
                return "\"" + input.creator + "\"";
            $throws({
                expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                value: input.creator
            });
        })() : undefined},`}${undefined === input.runId ? "" : `"runId":${undefined !== input.runId ? $string(input.runId) : undefined},`}"tagId1":${$string(input.tagId1)},"tagId2":${$string(input.tagId2)},"operation":${(() => {
            if ("string" === typeof input.operation)
                return $string(input.operation);
            if ("string" === typeof input.operation)
                return "\"" + input.operation + "\"";
            $throws({
                expected: "(\"DIFFERENCE\" | \"INTERSECTION\" | \"UNION\")",
                value: input.operation
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export type PerformTagArithmeticsBitmaskRequestBody = TagArithmeticsRequest;
export const PerformTagArithmeticsBitmaskRequestBodySanitizer = TagArithmeticsRequestFromJSON;
export const PerformTagArithmeticsBitmaskRequestBodyValidator = TagArithmeticsRequestValidator;
export const PerformTagArithmeticsBitmaskTypiaAssertEquals = (input: any) => {
    return ((input: any): PerformTagArithmeticsBitmaskRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is PerformTagArithmeticsBitmaskRequestBody => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.tagId1 && $is_custom("pattern_TagArithmeticsRequest_tagId1", "string", "", input.tagId1) && ("string" === typeof input.tagId2 && $is_custom("pattern_TagArithmeticsRequest_tagId2", "string", "", input.tagId2)) && ("UNION" === input.operation || "INTERSECTION" === input.operation || "DIFFERENCE" === input.operation) && (undefined === input.newTagName || "string" === typeof input.newTagName && 3 <= input.newTagName.length && $is_custom("pattern_TagArithmeticsRequest_newTagName", "string", "", input.newTagName)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator) && (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagArithmeticsRequest_runId", "string", "", input.runId)) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["tagId1", "tagId2", "operation", "newTagName", "creator", "runId"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PerformTagArithmeticsBitmaskRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.tagId1 && ($is_custom("pattern_TagArithmeticsRequest_tagId1", "string", "", input.tagId1) || $guard(_exceptionable, {
                    path: _path + ".tagId1",
                    expected: "string (@pattern_TagArithmeticsRequest_tagId1)",
                    value: input.tagId1
                })) || $guard(_exceptionable, {
                    path: _path + ".tagId1",
                    expected: "string",
                    value: input.tagId1
                })) && ("string" === typeof input.tagId2 && ($is_custom("pattern_TagArithmeticsRequest_tagId2", "string", "", input.tagId2) || $guard(_exceptionable, {
                    path: _path + ".tagId2",
                    expected: "string (@pattern_TagArithmeticsRequest_tagId2)",
                    value: input.tagId2
                })) || $guard(_exceptionable, {
                    path: _path + ".tagId2",
                    expected: "string",
                    value: input.tagId2
                })) && ("UNION" === input.operation || "INTERSECTION" === input.operation || "DIFFERENCE" === input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "(\"DIFFERENCE\" | \"INTERSECTION\" | \"UNION\")",
                    value: input.operation
                })) && (undefined === input.newTagName || "string" === typeof input.newTagName && (3 <= input.newTagName.length || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@minLength 3)",
                    value: input.newTagName
                })) && ($is_custom("pattern_TagArithmeticsRequest_newTagName", "string", "", input.newTagName) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@pattern_TagArithmeticsRequest_newTagName)",
                    value: input.newTagName
                })) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "(string | undefined)",
                    value: input.newTagName
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagArithmeticsRequest_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagArithmeticsRequest_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["tagId1", "tagId2", "operation", "newTagName", "creator", "runId"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagArithmeticsRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagArithmeticsRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const PerformTagArithmeticsBitmaskTypiaAssertStringify = (input: PerformTagArithmeticsBitmaskRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): PerformTagArithmeticsBitmaskRequestBody => {
        const __is = (input: any): input is PerformTagArithmeticsBitmaskRequestBody => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.tagId1 && $is_custom("pattern_TagArithmeticsRequest_tagId1", "string", "", input.tagId1) && ("string" === typeof input.tagId2 && $is_custom("pattern_TagArithmeticsRequest_tagId2", "string", "", input.tagId2)) && ("UNION" === input.operation || "INTERSECTION" === input.operation || "DIFFERENCE" === input.operation) && (undefined === input.newTagName || "string" === typeof input.newTagName && 3 <= input.newTagName.length && $is_custom("pattern_TagArithmeticsRequest_newTagName", "string", "", input.newTagName)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator) && (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagArithmeticsRequest_runId", "string", "", input.runId));
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PerformTagArithmeticsBitmaskRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.tagId1 && ($is_custom("pattern_TagArithmeticsRequest_tagId1", "string", "", input.tagId1) || $guard(_exceptionable, {
                    path: _path + ".tagId1",
                    expected: "string (@pattern_TagArithmeticsRequest_tagId1)",
                    value: input.tagId1
                })) || $guard(_exceptionable, {
                    path: _path + ".tagId1",
                    expected: "string",
                    value: input.tagId1
                })) && ("string" === typeof input.tagId2 && ($is_custom("pattern_TagArithmeticsRequest_tagId2", "string", "", input.tagId2) || $guard(_exceptionable, {
                    path: _path + ".tagId2",
                    expected: "string (@pattern_TagArithmeticsRequest_tagId2)",
                    value: input.tagId2
                })) || $guard(_exceptionable, {
                    path: _path + ".tagId2",
                    expected: "string",
                    value: input.tagId2
                })) && ("UNION" === input.operation || "INTERSECTION" === input.operation || "DIFFERENCE" === input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "(\"DIFFERENCE\" | \"INTERSECTION\" | \"UNION\")",
                    value: input.operation
                })) && (undefined === input.newTagName || "string" === typeof input.newTagName && (3 <= input.newTagName.length || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@minLength 3)",
                    value: input.newTagName
                })) && ($is_custom("pattern_TagArithmeticsRequest_newTagName", "string", "", input.newTagName) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@pattern_TagArithmeticsRequest_newTagName)",
                    value: input.newTagName
                })) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "(string | undefined)",
                    value: input.newTagName
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagArithmeticsRequest_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagArithmeticsRequest_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagArithmeticsRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagArithmeticsRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: PerformTagArithmeticsBitmaskRequestBody): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.newTagName ? "" : `"newTagName":${undefined !== input.newTagName ? $string(input.newTagName) : undefined},`}${undefined === input.creator ? "" : `"creator":${undefined !== input.creator ? (() => {
            if ("string" === typeof input.creator)
                return $string(input.creator);
            if ("string" === typeof input.creator)
                return "\"" + input.creator + "\"";
            $throws({
                expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                value: input.creator
            });
        })() : undefined},`}${undefined === input.runId ? "" : `"runId":${undefined !== input.runId ? $string(input.runId) : undefined},`}"tagId1":${$string(input.tagId1)},"tagId2":${$string(input.tagId2)},"operation":${(() => {
            if ("string" === typeof input.operation)
                return $string(input.operation);
            if ("string" === typeof input.operation)
                return "\"" + input.operation + "\"";
            $throws({
                expected: "(\"DIFFERENCE\" | \"INTERSECTION\" | \"UNION\")",
                value: input.operation
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export type UpdateTagByTagIdRequestBody = TagUpdateRequest;
export const UpdateTagByTagIdRequestBodySanitizer = TagUpdateRequestFromJSON;
export const UpdateTagByTagIdRequestBodyValidator = TagUpdateRequestValidator;
export const UpdateTagByTagIdTypiaAssertEquals = (input: any) => {
    return ((input: any): UpdateTagByTagIdRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is UpdateTagByTagIdRequestBody => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.updateCreator || "UNKNOWN" === input.updateCreator || "USER_WEBAPP" === input.updateCreator || "USER_PIP" === input.updateCreator || "USER_PIP_LIGHTLY_MAGIC" === input.updateCreator || "USER_WORKER" === input.updateCreator || "SAMPLER_ACTIVE_LEARNING" === input.updateCreator || "SAMPLER_CORAL" === input.updateCreator || "SAMPLER_CORESET" === input.updateCreator || "SAMPLER_RANDOM" === input.updateCreator) && ("string" === typeof input.name && 3 <= input.name.length && $is_custom("pattern_TagUpdateRequest_name", "string", "", input.name)) && (undefined === input.bitMaskData || "string" === typeof input.bitMaskData && $is_custom("pattern_TagUpdateRequest_bitMaskData", "string", "", input.bitMaskData)) && (undefined === input.changes || "object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io1(input.changes, true && _exceptionable)) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["updateCreator", "name", "bitMaskData", "changes"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.initial || "object" === typeof input.initial && null !== input.initial && false === Array.isArray(input.initial) && $io2(input.initial, true && _exceptionable)) && (undefined === input.rename || "object" === typeof input.rename && null !== input.rename && $io3(input.rename, true && _exceptionable)) && (undefined === input.upsize || "object" === typeof input.upsize && null !== input.upsize && $io4(input.upsize, true && _exceptionable)) && (undefined === input.arithmetics || "object" === typeof input.arithmetics && null !== input.arithmetics && $io5(input.arithmetics, true && _exceptionable)) && (undefined === input.metadata || "object" === typeof input.metadata && null !== input.metadata && $io6(input.metadata, true && _exceptionable)) && (undefined === input.samples || "object" === typeof input.samples && null !== input.samples && $io8(input.samples, true && _exceptionable)) && (undefined === input.scatterplot || "object" === typeof input.scatterplot && null !== input.scatterplot && $io9(input.scatterplot, true && _exceptionable)) && (undefined === input.sampler || "object" === typeof input.sampler && null !== input.sampler && $io10(input.sampler, true && _exceptionable)) && Object.keys(input).every((key: any) => {
                if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagChangeDataInitial_runId", "string", "", input.runId)) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["runId"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io3 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.from && "string" === typeof input.to && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["from", "to"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io4 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagChangeDataUpsize_runId", "string", "", input.runId)) && "number" === typeof input.from && "number" === typeof input.to && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["runId", "from", "to"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io5 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.operation && "string" === typeof input.tag1 && "string" === typeof input.tag2 && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["operation", "tag1", "tag2"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io6 = (input: any, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed) && ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io7(input.changes, true && _exceptionable)) && (5 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["method", "count", "added", "removed", "changes"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io7 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io8 = (input: any, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["method", "count", "added", "removed"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io9 = (input: any, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && (undefined === input.view || "string" === typeof input.view) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["method", "view", "count", "added", "removed"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io10 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.method && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["method"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is UpdateTagByTagIdRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.updateCreator || "UNKNOWN" === input.updateCreator || "USER_WEBAPP" === input.updateCreator || "USER_PIP" === input.updateCreator || "USER_PIP_LIGHTLY_MAGIC" === input.updateCreator || "USER_WORKER" === input.updateCreator || "SAMPLER_ACTIVE_LEARNING" === input.updateCreator || "SAMPLER_CORAL" === input.updateCreator || "SAMPLER_CORESET" === input.updateCreator || "SAMPLER_RANDOM" === input.updateCreator || $guard(_exceptionable, {
                    path: _path + ".updateCreator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.updateCreator
                })) && ("string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_TagUpdateRequest_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_TagUpdateRequest_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (undefined === input.bitMaskData || "string" === typeof input.bitMaskData && ($is_custom("pattern_TagUpdateRequest_bitMaskData", "string", "", input.bitMaskData) || $guard(_exceptionable, {
                    path: _path + ".bitMaskData",
                    expected: "string (@pattern_TagUpdateRequest_bitMaskData)",
                    value: input.bitMaskData
                })) || $guard(_exceptionable, {
                    path: _path + ".bitMaskData",
                    expected: "(string | undefined)",
                    value: input.bitMaskData
                })) && (undefined === input.changes || ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "(TagChangeData | undefined)",
                    value: input.changes
                })) && $ao1(input.changes, _path + ".changes", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "(TagChangeData | undefined)",
                    value: input.changes
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["updateCreator", "name", "bitMaskData", "changes"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.initial || ("object" === typeof input.initial && null !== input.initial && false === Array.isArray(input.initial) || $guard(_exceptionable, {
                    path: _path + ".initial",
                    expected: "(TagChangeDataInitial | undefined)",
                    value: input.initial
                })) && $ao2(input.initial, _path + ".initial", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".initial",
                    expected: "(TagChangeDataInitial | undefined)",
                    value: input.initial
                })) && (undefined === input.rename || ("object" === typeof input.rename && null !== input.rename || $guard(_exceptionable, {
                    path: _path + ".rename",
                    expected: "(TagChangeDataRename | undefined)",
                    value: input.rename
                })) && $ao3(input.rename, _path + ".rename", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".rename",
                    expected: "(TagChangeDataRename | undefined)",
                    value: input.rename
                })) && (undefined === input.upsize || ("object" === typeof input.upsize && null !== input.upsize || $guard(_exceptionable, {
                    path: _path + ".upsize",
                    expected: "(TagChangeDataUpsize | undefined)",
                    value: input.upsize
                })) && $ao4(input.upsize, _path + ".upsize", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".upsize",
                    expected: "(TagChangeDataUpsize | undefined)",
                    value: input.upsize
                })) && (undefined === input.arithmetics || ("object" === typeof input.arithmetics && null !== input.arithmetics || $guard(_exceptionable, {
                    path: _path + ".arithmetics",
                    expected: "(TagChangeDataArithmetics | undefined)",
                    value: input.arithmetics
                })) && $ao5(input.arithmetics, _path + ".arithmetics", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".arithmetics",
                    expected: "(TagChangeDataArithmetics | undefined)",
                    value: input.arithmetics
                })) && (undefined === input.metadata || ("object" === typeof input.metadata && null !== input.metadata || $guard(_exceptionable, {
                    path: _path + ".metadata",
                    expected: "(TagChangeDataMetadata | undefined)",
                    value: input.metadata
                })) && $ao6(input.metadata, _path + ".metadata", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".metadata",
                    expected: "(TagChangeDataMetadata | undefined)",
                    value: input.metadata
                })) && (undefined === input.samples || ("object" === typeof input.samples && null !== input.samples || $guard(_exceptionable, {
                    path: _path + ".samples",
                    expected: "(TagChangeDataSamples | undefined)",
                    value: input.samples
                })) && $ao8(input.samples, _path + ".samples", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".samples",
                    expected: "(TagChangeDataSamples | undefined)",
                    value: input.samples
                })) && (undefined === input.scatterplot || ("object" === typeof input.scatterplot && null !== input.scatterplot || $guard(_exceptionable, {
                    path: _path + ".scatterplot",
                    expected: "(TagChangeDataScatterplot | undefined)",
                    value: input.scatterplot
                })) && $ao9(input.scatterplot, _path + ".scatterplot", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".scatterplot",
                    expected: "(TagChangeDataScatterplot | undefined)",
                    value: input.scatterplot
                })) && (undefined === input.sampler || ("object" === typeof input.sampler && null !== input.sampler || $guard(_exceptionable, {
                    path: _path + ".sampler",
                    expected: "(TagChangeDataSampler | undefined)",
                    value: input.sampler
                })) && $ao10(input.sampler, _path + ".sampler", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".sampler",
                    expected: "(TagChangeDataSampler | undefined)",
                    value: input.sampler
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagChangeDataInitial_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagChangeDataInitial_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["runId"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.from || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "string",
                    value: input.from
                })) && ("string" === typeof input.to || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "string",
                    value: input.to
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["from", "to"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagChangeDataUpsize_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagChangeDataUpsize_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && ("number" === typeof input.from || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "number",
                    value: input.from
                })) && ("number" === typeof input.to || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "number",
                    value: input.to
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["runId", "from", "to"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "string",
                    value: input.operation
                })) && ("string" === typeof input.tag1 || $guard(_exceptionable, {
                    path: _path + ".tag1",
                    expected: "string",
                    value: input.tag1
                })) && ("string" === typeof input.tag2 || $guard(_exceptionable, {
                    path: _path + ".tag2",
                    expected: "string",
                    value: input.tag2
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["operation", "tag1", "tag2"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && ("number" === typeof input.count && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                })) && (("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                })) && $ao7(input.changes, _path + ".changes", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["method", "count", "added", "removed", "changes"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && ("number" === typeof input.count && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["method", "count", "added", "removed"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao9 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && (undefined === input.view || "string" === typeof input.view || $guard(_exceptionable, {
                    path: _path + ".view",
                    expected: "(string | undefined)",
                    value: input.view
                })) && ("number" === typeof input.count && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["method", "view", "count", "added", "removed"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao10 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "string",
                    value: input.method
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["method"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagUpdateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagUpdateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const UpdateTagByTagIdTypiaAssertStringify = (input: UpdateTagByTagIdRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): UpdateTagByTagIdRequestBody => {
        const __is = (input: any): input is UpdateTagByTagIdRequestBody => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => (undefined === input.updateCreator || "UNKNOWN" === input.updateCreator || "USER_WEBAPP" === input.updateCreator || "USER_PIP" === input.updateCreator || "USER_PIP_LIGHTLY_MAGIC" === input.updateCreator || "USER_WORKER" === input.updateCreator || "SAMPLER_ACTIVE_LEARNING" === input.updateCreator || "SAMPLER_CORAL" === input.updateCreator || "SAMPLER_CORESET" === input.updateCreator || "SAMPLER_RANDOM" === input.updateCreator) && ("string" === typeof input.name && 3 <= input.name.length && $is_custom("pattern_TagUpdateRequest_name", "string", "", input.name)) && (undefined === input.bitMaskData || "string" === typeof input.bitMaskData && $is_custom("pattern_TagUpdateRequest_bitMaskData", "string", "", input.bitMaskData)) && (undefined === input.changes || "object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io1(input.changes));
            const $io1 = (input: any): boolean => (undefined === input.initial || "object" === typeof input.initial && null !== input.initial && false === Array.isArray(input.initial) && $io2(input.initial)) && (undefined === input.rename || "object" === typeof input.rename && null !== input.rename && $io3(input.rename)) && (undefined === input.upsize || "object" === typeof input.upsize && null !== input.upsize && $io4(input.upsize)) && (undefined === input.arithmetics || "object" === typeof input.arithmetics && null !== input.arithmetics && $io5(input.arithmetics)) && (undefined === input.metadata || "object" === typeof input.metadata && null !== input.metadata && $io6(input.metadata)) && (undefined === input.samples || "object" === typeof input.samples && null !== input.samples && $io8(input.samples)) && (undefined === input.scatterplot || "object" === typeof input.scatterplot && null !== input.scatterplot && $io9(input.scatterplot)) && (undefined === input.sampler || "object" === typeof input.sampler && null !== input.sampler && $io10(input.sampler)) && Object.keys(input).every((key: any) => {
                if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io2 = (input: any): boolean => undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagChangeDataInitial_runId", "string", "", input.runId);
            const $io3 = (input: any): boolean => "string" === typeof input.from && "string" === typeof input.to;
            const $io4 = (input: any): boolean => (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagChangeDataUpsize_runId", "string", "", input.runId)) && ("number" === typeof input.from && !Number.isNaN(input.from)) && ("number" === typeof input.to && !Number.isNaN(input.to));
            const $io5 = (input: any): boolean => "string" === typeof input.operation && "string" === typeof input.tag1 && "string" === typeof input.tag2;
            const $io6 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && !Number.isNaN(input.count) && 0 <= input.count) && ("number" === typeof input.added && !Number.isNaN(input.added) && 0 <= input.added) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && 0 <= input.removed) && ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io7(input.changes));
            const $io7 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io8 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && !Number.isNaN(input.count) && 0 <= input.count) && ("number" === typeof input.added && !Number.isNaN(input.added) && 0 <= input.added) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && 0 <= input.removed);
            const $io9 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && (undefined === input.view || "string" === typeof input.view) && ("number" === typeof input.count && !Number.isNaN(input.count) && 0 <= input.count) && ("number" === typeof input.added && !Number.isNaN(input.added) && 0 <= input.added) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && 0 <= input.removed);
            const $io10 = (input: any): boolean => "string" === typeof input.method;
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is UpdateTagByTagIdRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.updateCreator || "UNKNOWN" === input.updateCreator || "USER_WEBAPP" === input.updateCreator || "USER_PIP" === input.updateCreator || "USER_PIP_LIGHTLY_MAGIC" === input.updateCreator || "USER_WORKER" === input.updateCreator || "SAMPLER_ACTIVE_LEARNING" === input.updateCreator || "SAMPLER_CORAL" === input.updateCreator || "SAMPLER_CORESET" === input.updateCreator || "SAMPLER_RANDOM" === input.updateCreator || $guard(_exceptionable, {
                    path: _path + ".updateCreator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.updateCreator
                })) && ("string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_TagUpdateRequest_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_TagUpdateRequest_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (undefined === input.bitMaskData || "string" === typeof input.bitMaskData && ($is_custom("pattern_TagUpdateRequest_bitMaskData", "string", "", input.bitMaskData) || $guard(_exceptionable, {
                    path: _path + ".bitMaskData",
                    expected: "string (@pattern_TagUpdateRequest_bitMaskData)",
                    value: input.bitMaskData
                })) || $guard(_exceptionable, {
                    path: _path + ".bitMaskData",
                    expected: "(string | undefined)",
                    value: input.bitMaskData
                })) && (undefined === input.changes || ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "(TagChangeData | undefined)",
                    value: input.changes
                })) && $ao1(input.changes, _path + ".changes", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "(TagChangeData | undefined)",
                    value: input.changes
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.initial || ("object" === typeof input.initial && null !== input.initial && false === Array.isArray(input.initial) || $guard(_exceptionable, {
                    path: _path + ".initial",
                    expected: "(TagChangeDataInitial | undefined)",
                    value: input.initial
                })) && $ao2(input.initial, _path + ".initial", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".initial",
                    expected: "(TagChangeDataInitial | undefined)",
                    value: input.initial
                })) && (undefined === input.rename || ("object" === typeof input.rename && null !== input.rename || $guard(_exceptionable, {
                    path: _path + ".rename",
                    expected: "(TagChangeDataRename | undefined)",
                    value: input.rename
                })) && $ao3(input.rename, _path + ".rename", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".rename",
                    expected: "(TagChangeDataRename | undefined)",
                    value: input.rename
                })) && (undefined === input.upsize || ("object" === typeof input.upsize && null !== input.upsize || $guard(_exceptionable, {
                    path: _path + ".upsize",
                    expected: "(TagChangeDataUpsize | undefined)",
                    value: input.upsize
                })) && $ao4(input.upsize, _path + ".upsize", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".upsize",
                    expected: "(TagChangeDataUpsize | undefined)",
                    value: input.upsize
                })) && (undefined === input.arithmetics || ("object" === typeof input.arithmetics && null !== input.arithmetics || $guard(_exceptionable, {
                    path: _path + ".arithmetics",
                    expected: "(TagChangeDataArithmetics | undefined)",
                    value: input.arithmetics
                })) && $ao5(input.arithmetics, _path + ".arithmetics", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".arithmetics",
                    expected: "(TagChangeDataArithmetics | undefined)",
                    value: input.arithmetics
                })) && (undefined === input.metadata || ("object" === typeof input.metadata && null !== input.metadata || $guard(_exceptionable, {
                    path: _path + ".metadata",
                    expected: "(TagChangeDataMetadata | undefined)",
                    value: input.metadata
                })) && $ao6(input.metadata, _path + ".metadata", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".metadata",
                    expected: "(TagChangeDataMetadata | undefined)",
                    value: input.metadata
                })) && (undefined === input.samples || ("object" === typeof input.samples && null !== input.samples || $guard(_exceptionable, {
                    path: _path + ".samples",
                    expected: "(TagChangeDataSamples | undefined)",
                    value: input.samples
                })) && $ao8(input.samples, _path + ".samples", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".samples",
                    expected: "(TagChangeDataSamples | undefined)",
                    value: input.samples
                })) && (undefined === input.scatterplot || ("object" === typeof input.scatterplot && null !== input.scatterplot || $guard(_exceptionable, {
                    path: _path + ".scatterplot",
                    expected: "(TagChangeDataScatterplot | undefined)",
                    value: input.scatterplot
                })) && $ao9(input.scatterplot, _path + ".scatterplot", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".scatterplot",
                    expected: "(TagChangeDataScatterplot | undefined)",
                    value: input.scatterplot
                })) && (undefined === input.sampler || ("object" === typeof input.sampler && null !== input.sampler || $guard(_exceptionable, {
                    path: _path + ".sampler",
                    expected: "(TagChangeDataSampler | undefined)",
                    value: input.sampler
                })) && $ao10(input.sampler, _path + ".sampler", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".sampler",
                    expected: "(TagChangeDataSampler | undefined)",
                    value: input.sampler
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagChangeDataInitial_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagChangeDataInitial_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                });
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.from || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "string",
                    value: input.from
                })) && ("string" === typeof input.to || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "string",
                    value: input.to
                }));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagChangeDataUpsize_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagChangeDataUpsize_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && ("number" === typeof input.from && !Number.isNaN(input.from) || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "number",
                    value: input.from
                })) && ("number" === typeof input.to && !Number.isNaN(input.to) || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "number",
                    value: input.to
                }));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "string",
                    value: input.operation
                })) && ("string" === typeof input.tag1 || $guard(_exceptionable, {
                    path: _path + ".tag1",
                    expected: "string",
                    value: input.tag1
                })) && ("string" === typeof input.tag2 || $guard(_exceptionable, {
                    path: _path + ".tag2",
                    expected: "string",
                    value: input.tag2
                }));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && ("number" === typeof input.count && !Number.isNaN(input.count) && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && !Number.isNaN(input.added) && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                })) && (("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                })) && $ao7(input.changes, _path + ".changes", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                }));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && ("number" === typeof input.count && !Number.isNaN(input.count) && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && !Number.isNaN(input.added) && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                }));
                const $ao9 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && (undefined === input.view || "string" === typeof input.view || $guard(_exceptionable, {
                    path: _path + ".view",
                    expected: "(string | undefined)",
                    value: input.view
                })) && ("number" === typeof input.count && !Number.isNaN(input.count) && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && !Number.isNaN(input.added) && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                }));
                const $ao10 = (input: any, _path: string, _exceptionable: boolean = true): boolean => "string" === typeof input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "string",
                    value: input.method
                });
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagUpdateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagUpdateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: UpdateTagByTagIdRequestBody): string => {
        const $io1 = (input: any): boolean => (undefined === input.initial || "object" === typeof input.initial && null !== input.initial && false === Array.isArray(input.initial) && $io2(input.initial)) && (undefined === input.rename || "object" === typeof input.rename && null !== input.rename && $io3(input.rename)) && (undefined === input.upsize || "object" === typeof input.upsize && null !== input.upsize && $io4(input.upsize)) && (undefined === input.arithmetics || "object" === typeof input.arithmetics && null !== input.arithmetics && $io5(input.arithmetics)) && (undefined === input.metadata || "object" === typeof input.metadata && null !== input.metadata && $io6(input.metadata)) && (undefined === input.samples || "object" === typeof input.samples && null !== input.samples && $io8(input.samples)) && (undefined === input.scatterplot || "object" === typeof input.scatterplot && null !== input.scatterplot && $io9(input.scatterplot)) && (undefined === input.sampler || "object" === typeof input.sampler && null !== input.sampler && $io10(input.sampler)) && Object.keys(input).every((key: any) => {
            if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some((prop: any) => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io2 = (input: any): boolean => undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagChangeDataInitial_runId", "string", "", input.runId);
        const $io3 = (input: any): boolean => "string" === typeof input.from && "string" === typeof input.to;
        const $io4 = (input: any): boolean => (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagChangeDataUpsize_runId", "string", "", input.runId)) && "number" === typeof input.from && "number" === typeof input.to;
        const $io5 = (input: any): boolean => "string" === typeof input.operation && "string" === typeof input.tag1 && "string" === typeof input.tag2;
        const $io6 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed) && ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io7(input.changes));
        const $io7 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io8 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed);
        const $io9 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && (undefined === input.view || "string" === typeof input.view) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed);
        const $io10 = (input: any): boolean => "string" === typeof input.method;
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.updateCreator ? "" : `"updateCreator":${undefined !== input.updateCreator ? (() => {
            if ("string" === typeof input.updateCreator)
                return $string(input.updateCreator);
            if ("string" === typeof input.updateCreator)
                return "\"" + input.updateCreator + "\"";
            $throws({
                expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                value: input.updateCreator
            });
        })() : undefined},`}${undefined === input.bitMaskData ? "" : `"bitMaskData":${undefined !== input.bitMaskData ? $string(input.bitMaskData) : undefined},`}${undefined === input.changes ? "" : `"changes":${undefined !== input.changes ? $so1(input.changes) : undefined},`}"name":${$string(input.name)}}`;
        const $so1 = (input: any): any => `{${$tail(`${undefined === input.initial ? "" : `"initial":${undefined !== input.initial ? $so2(input.initial) : undefined},`}${undefined === input.rename ? "" : `"rename":${undefined !== input.rename ? `{"from":${$string((input.rename as any).from)},"to":${$string((input.rename as any).to)}}` : undefined},`}${undefined === input.upsize ? "" : `"upsize":${undefined !== input.upsize ? $so4(input.upsize) : undefined},`}${undefined === input.arithmetics ? "" : `"arithmetics":${undefined !== input.arithmetics ? `{"operation":${$string((input.arithmetics as any).operation)},"tag1":${$string((input.arithmetics as any).tag1)},"tag2":${$string((input.arithmetics as any).tag2)}}` : undefined},`}${undefined === input.metadata ? "" : `"metadata":${undefined !== input.metadata ? $so6(input.metadata) : undefined},`}${undefined === input.samples ? "" : `"samples":${undefined !== input.samples ? $so8(input.samples) : undefined},`}${undefined === input.scatterplot ? "" : `"scatterplot":${undefined !== input.scatterplot ? $so9(input.scatterplot) : undefined},`}${undefined === input.sampler ? "" : `"sampler":${undefined !== input.sampler ? `{"method":${$string((input.sampler as any).method)}}` : undefined},`}${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some((regular: any) => regular === key))
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        const $so2 = (input: any): any => `{${$tail(`${undefined === input.runId ? "" : `"runId":${undefined !== input.runId ? $string(input.runId) : undefined}`}`)}}`;
        const $so4 = (input: any): any => `{${undefined === input.runId ? "" : `"runId":${undefined !== input.runId ? $string(input.runId) : undefined},`}"from":${input.from},"to":${input.to}}`;
        const $so6 = (input: any): any => `{"method":${(() => {
            if ("string" === typeof input.method)
                return $string(input.method);
            if ("string" === typeof input.method)
                return "\"" + input.method + "\"";
            $throws({
                expected: "(\"added\" | \"removed\" | \"selected\")",
                value: input.method
            });
        })()},"count":${input.count},"added":${input.added},"removed":${input.removed},"changes":${$so7(input.changes)}}`;
        const $so7 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so8 = (input: any): any => `{"method":${(() => {
            if ("string" === typeof input.method)
                return $string(input.method);
            if ("string" === typeof input.method)
                return "\"" + input.method + "\"";
            $throws({
                expected: "(\"added\" | \"removed\" | \"selected\")",
                value: input.method
            });
        })()},"count":${input.count},"added":${input.added},"removed":${input.removed}}`;
        const $so9 = (input: any): any => `{${undefined === input.view ? "" : `"view":${undefined !== input.view ? $string(input.view) : undefined},`}"method":${(() => {
            if ("string" === typeof input.method)
                return $string(input.method);
            if ("string" === typeof input.method)
                return "\"" + input.method + "\"";
            $throws({
                expected: "(\"added\" | \"removed\" | \"selected\")",
                value: input.method
            });
        })()},"count":${input.count},"added":${input.added},"removed":${input.removed}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export type UpsizeTagsByDatasetIdRequestBody = TagUpsizeRequest;
export const UpsizeTagsByDatasetIdRequestBodySanitizer = TagUpsizeRequestFromJSON;
export const UpsizeTagsByDatasetIdRequestBodyValidator = TagUpsizeRequestValidator;
export const UpsizeTagsByDatasetIdTypiaAssertEquals = (input: any) => {
    return ((input: any): UpsizeTagsByDatasetIdRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is UpsizeTagsByDatasetIdRequestBody => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.upsizeTagName && 3 <= input.upsizeTagName.length && $is_custom("pattern_TagUpsizeRequest_upsizeTagName", "string", "", input.upsizeTagName) && ("UNKNOWN" === input.upsizeTagCreator || "USER_WEBAPP" === input.upsizeTagCreator || "USER_PIP" === input.upsizeTagCreator || "USER_PIP_LIGHTLY_MAGIC" === input.upsizeTagCreator || "USER_WORKER" === input.upsizeTagCreator || "SAMPLER_ACTIVE_LEARNING" === input.upsizeTagCreator || "SAMPLER_CORAL" === input.upsizeTagCreator || "SAMPLER_CORESET" === input.upsizeTagCreator || "SAMPLER_RANDOM" === input.upsizeTagCreator) && (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagUpsizeRequest_runId", "string", "", input.runId)) && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["upsizeTagName", "upsizeTagCreator", "runId"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is UpsizeTagsByDatasetIdRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.upsizeTagName && (3 <= input.upsizeTagName.length || $guard(_exceptionable, {
                    path: _path + ".upsizeTagName",
                    expected: "string (@minLength 3)",
                    value: input.upsizeTagName
                })) && ($is_custom("pattern_TagUpsizeRequest_upsizeTagName", "string", "", input.upsizeTagName) || $guard(_exceptionable, {
                    path: _path + ".upsizeTagName",
                    expected: "string (@pattern_TagUpsizeRequest_upsizeTagName)",
                    value: input.upsizeTagName
                })) || $guard(_exceptionable, {
                    path: _path + ".upsizeTagName",
                    expected: "string",
                    value: input.upsizeTagName
                })) && ("UNKNOWN" === input.upsizeTagCreator || "USER_WEBAPP" === input.upsizeTagCreator || "USER_PIP" === input.upsizeTagCreator || "USER_PIP_LIGHTLY_MAGIC" === input.upsizeTagCreator || "USER_WORKER" === input.upsizeTagCreator || "SAMPLER_ACTIVE_LEARNING" === input.upsizeTagCreator || "SAMPLER_CORAL" === input.upsizeTagCreator || "SAMPLER_CORESET" === input.upsizeTagCreator || "SAMPLER_RANDOM" === input.upsizeTagCreator || $guard(_exceptionable, {
                    path: _path + ".upsizeTagCreator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\")",
                    value: input.upsizeTagCreator
                })) && (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagUpsizeRequest_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagUpsizeRequest_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["upsizeTagName", "upsizeTagCreator", "runId"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagUpsizeRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagUpsizeRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const UpsizeTagsByDatasetIdTypiaAssertStringify = (input: UpsizeTagsByDatasetIdRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): UpsizeTagsByDatasetIdRequestBody => {
        const __is = (input: any): input is UpsizeTagsByDatasetIdRequestBody => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.upsizeTagName && 3 <= input.upsizeTagName.length && $is_custom("pattern_TagUpsizeRequest_upsizeTagName", "string", "", input.upsizeTagName) && ("UNKNOWN" === input.upsizeTagCreator || "USER_WEBAPP" === input.upsizeTagCreator || "USER_PIP" === input.upsizeTagCreator || "USER_PIP_LIGHTLY_MAGIC" === input.upsizeTagCreator || "USER_WORKER" === input.upsizeTagCreator || "SAMPLER_ACTIVE_LEARNING" === input.upsizeTagCreator || "SAMPLER_CORAL" === input.upsizeTagCreator || "SAMPLER_CORESET" === input.upsizeTagCreator || "SAMPLER_RANDOM" === input.upsizeTagCreator) && (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagUpsizeRequest_runId", "string", "", input.runId));
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is UpsizeTagsByDatasetIdRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.upsizeTagName && (3 <= input.upsizeTagName.length || $guard(_exceptionable, {
                    path: _path + ".upsizeTagName",
                    expected: "string (@minLength 3)",
                    value: input.upsizeTagName
                })) && ($is_custom("pattern_TagUpsizeRequest_upsizeTagName", "string", "", input.upsizeTagName) || $guard(_exceptionable, {
                    path: _path + ".upsizeTagName",
                    expected: "string (@pattern_TagUpsizeRequest_upsizeTagName)",
                    value: input.upsizeTagName
                })) || $guard(_exceptionable, {
                    path: _path + ".upsizeTagName",
                    expected: "string",
                    value: input.upsizeTagName
                })) && ("UNKNOWN" === input.upsizeTagCreator || "USER_WEBAPP" === input.upsizeTagCreator || "USER_PIP" === input.upsizeTagCreator || "USER_PIP_LIGHTLY_MAGIC" === input.upsizeTagCreator || "USER_WORKER" === input.upsizeTagCreator || "SAMPLER_ACTIVE_LEARNING" === input.upsizeTagCreator || "SAMPLER_CORAL" === input.upsizeTagCreator || "SAMPLER_CORESET" === input.upsizeTagCreator || "SAMPLER_RANDOM" === input.upsizeTagCreator || $guard(_exceptionable, {
                    path: _path + ".upsizeTagCreator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\")",
                    value: input.upsizeTagCreator
                })) && (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagUpsizeRequest_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagUpsizeRequest_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagUpsizeRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagUpsizeRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: UpsizeTagsByDatasetIdRequestBody): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.runId ? "" : `"runId":${undefined !== input.runId ? $string(input.runId) : undefined},`}"upsizeTagName":${$string(input.upsizeTagName)},"upsizeTagCreator":${(() => {
            if ("string" === typeof input.upsizeTagCreator)
                return $string(input.upsizeTagCreator);
            if ("string" === typeof input.upsizeTagCreator)
                return "\"" + input.upsizeTagCreator + "\"";
            $throws({
                expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\")",
                value: input.upsizeTagCreator
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
/*
HANDLERS:



/v1/datasets/{datasetId}/tags/initial

export const createInitialTagByDatasetIdFN: RequestHandler<
    CreateInitialTagByDatasetIdRequestParams,
    CreateEntityResponse | ApiErrorResponse,
    CreateInitialTagByDatasetIdRequestBody,
    CreateInitialTagByDatasetIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, CreateInitialTagByDatasetIdRequestBodySanitizer, CreateInitialTagByDatasetIdRequestBodyValidator);
    const {
        
        datasetId,
    } =  await validateAndSanitize(req.params, CreateInitialTagByDatasetIdRequestParamsSanitizer, CreateInitialTagByDatasetIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, CreateInitialTagByDatasetIdRequestQuerySanitizer, CreateInitialTagByDatasetIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        CreateEntityResponseStringify,
        validateAndRespond({ id, ...rest }, CreateEntityResponseFromJSON, CreateEntityResponseValidator),
    );
}



/v1/datasets/{datasetId}/tags

export const createTagByDatasetIdFN: RequestHandler<
    CreateTagByDatasetIdRequestParams,
    CreateEntityResponse | ApiErrorResponse,
    CreateTagByDatasetIdRequestBody,
    CreateTagByDatasetIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, CreateTagByDatasetIdRequestBodySanitizer, CreateTagByDatasetIdRequestBodyValidator);
    const {
        
        datasetId,
    } =  await validateAndSanitize(req.params, CreateTagByDatasetIdRequestParamsSanitizer, CreateTagByDatasetIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, CreateTagByDatasetIdRequestQuerySanitizer, CreateTagByDatasetIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        CreateEntityResponseStringify,
        validateAndRespond({ id, ...rest }, CreateEntityResponseFromJSON, CreateEntityResponseValidator),
    );
}



/v1/datasets/{datasetId}/tags/{tagId}

export const deleteTagByTagIdFN: RequestHandler<
    DeleteTagByTagIdRequestParams,
     | ApiErrorResponse,
    DeleteTagByTagIdRequestBody,
    DeleteTagByTagIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, DeleteTagByTagIdRequestBodySanitizer, DeleteTagByTagIdRequestBodyValidator);
    const {
        datasetId,
        tagId,
    } =  await validateAndSanitize(req.params, DeleteTagByTagIdRequestParamsSanitizer, DeleteTagByTagIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, DeleteTagByTagIdRequestQuerySanitizer, DeleteTagByTagIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        Stringify,
        validateAndRespond({ id, ...rest }, FromJSON, Validator),
    );
}



/v1/datasets/{datasetId}/tags/{tagId}/export/zip

export const downloadZipOfSamplesByTagIdFN: RequestHandler<
    DownloadZipOfSamplesByTagIdRequestParams,
    any | ApiErrorResponse,
    DownloadZipOfSamplesByTagIdRequestBody,
    DownloadZipOfSamplesByTagIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, DownloadZipOfSamplesByTagIdRequestBodySanitizer, DownloadZipOfSamplesByTagIdRequestBodyValidator);
    const {
        datasetId,
        tagId,
    } =  await validateAndSanitize(req.params, DownloadZipOfSamplesByTagIdRequestParamsSanitizer, DownloadZipOfSamplesByTagIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, DownloadZipOfSamplesByTagIdRequestQuerySanitizer, DownloadZipOfSamplesByTagIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        anyStringify,
        validateAndRespond({ id, ...rest }, anyFromJSON, anyValidator),
    );
}



/v1/datasets/{datasetId}/tags/{tagId}/export/basic/filenames

export const exportTagToBasicFilenamesFN: RequestHandler<
    ExportTagToBasicFilenamesRequestParams,
    string | ApiErrorResponse,
    ExportTagToBasicFilenamesRequestBody,
    ExportTagToBasicFilenamesRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, ExportTagToBasicFilenamesRequestBodySanitizer, ExportTagToBasicFilenamesRequestBodyValidator);
    const {
        datasetId,
        tagId,
        
        
        
        
        
        
        
        
    } =  await validateAndSanitize(req.params, ExportTagToBasicFilenamesRequestParamsSanitizer, ExportTagToBasicFilenamesRequestParamsValidator);
    const {
        
        
        expiresIn,
        accessControl,
        fileNameFormat,
        includeMetaData,
        format,
        previewExample,
        pageSize,
        pageOffset,
    } = await validateAndSanitize(req.query, ExportTagToBasicFilenamesRequestQuerySanitizer, ExportTagToBasicFilenamesRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        stringStringify,
        validateAndRespond({ id, ...rest }, stringFromJSON, stringValidator),
    );
}



/v1/datasets/{datasetId}/tags/{tagId}/export/basic/filenamesAndReadUrls

export const exportTagToBasicFilenamesAndReadUrlsFN: RequestHandler<
    ExportTagToBasicFilenamesAndReadUrlsRequestParams,
    FilenameAndReadUrls | ApiErrorResponse,
    ExportTagToBasicFilenamesAndReadUrlsRequestBody,
    ExportTagToBasicFilenamesAndReadUrlsRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, ExportTagToBasicFilenamesAndReadUrlsRequestBodySanitizer, ExportTagToBasicFilenamesAndReadUrlsRequestBodyValidator);
    const {
        datasetId,
        tagId,
        
        
        
        
    } =  await validateAndSanitize(req.params, ExportTagToBasicFilenamesAndReadUrlsRequestParamsSanitizer, ExportTagToBasicFilenamesAndReadUrlsRequestParamsValidator);
    const {
        
        
        format,
        previewExample,
        pageSize,
        pageOffset,
    } = await validateAndSanitize(req.query, ExportTagToBasicFilenamesAndReadUrlsRequestQuerySanitizer, ExportTagToBasicFilenamesAndReadUrlsRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        FilenameAndReadUrlsStringify,
        validateAndRespond({ id, ...rest }, FilenameAndReadUrlsFromJSON, FilenameAndReadUrlsValidator),
    );
}



/v1/datasets/{datasetId}/tags/{tagId}/export/LabelBox/datarows

export const exportTagToLabelBoxDataRowsFN: RequestHandler<
    ExportTagToLabelBoxDataRowsRequestParams,
    LabelBoxDataRows | ApiErrorResponse,
    ExportTagToLabelBoxDataRowsRequestBody,
    ExportTagToLabelBoxDataRowsRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, ExportTagToLabelBoxDataRowsRequestBodySanitizer, ExportTagToLabelBoxDataRowsRequestBodyValidator);
    const {
        datasetId,
        tagId,
        
        
        
        
        
        
        
        
    } =  await validateAndSanitize(req.params, ExportTagToLabelBoxDataRowsRequestParamsSanitizer, ExportTagToLabelBoxDataRowsRequestParamsValidator);
    const {
        
        
        expiresIn,
        accessControl,
        fileNameFormat,
        includeMetaData,
        format,
        previewExample,
        pageSize,
        pageOffset,
    } = await validateAndSanitize(req.query, ExportTagToLabelBoxDataRowsRequestQuerySanitizer, ExportTagToLabelBoxDataRowsRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        LabelBoxDataRowsStringify,
        validateAndRespond({ id, ...rest }, LabelBoxDataRowsFromJSON, LabelBoxDataRowsValidator),
    );
}



/v1/datasets/{datasetId}/tags/{tagId}/export/LabelBoxV4/datarows

export const exportTagToLabelBoxV4DataRowsFN: RequestHandler<
    ExportTagToLabelBoxV4DataRowsRequestParams,
    LabelBoxV4DataRows | ApiErrorResponse,
    ExportTagToLabelBoxV4DataRowsRequestBody,
    ExportTagToLabelBoxV4DataRowsRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, ExportTagToLabelBoxV4DataRowsRequestBodySanitizer, ExportTagToLabelBoxV4DataRowsRequestBodyValidator);
    const {
        datasetId,
        tagId,
        
        
        
        
        
        
        
        
    } =  await validateAndSanitize(req.params, ExportTagToLabelBoxV4DataRowsRequestParamsSanitizer, ExportTagToLabelBoxV4DataRowsRequestParamsValidator);
    const {
        
        
        expiresIn,
        accessControl,
        fileNameFormat,
        includeMetaData,
        format,
        previewExample,
        pageSize,
        pageOffset,
    } = await validateAndSanitize(req.query, ExportTagToLabelBoxV4DataRowsRequestQuerySanitizer, ExportTagToLabelBoxV4DataRowsRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        LabelBoxV4DataRowsStringify,
        validateAndRespond({ id, ...rest }, LabelBoxV4DataRowsFromJSON, LabelBoxV4DataRowsValidator),
    );
}



/v1/datasets/{datasetId}/tags/{tagId}/export/LabelStudio/tasks

export const exportTagToLabelStudioTasksFN: RequestHandler<
    ExportTagToLabelStudioTasksRequestParams,
    LabelStudioTasks | ApiErrorResponse,
    ExportTagToLabelStudioTasksRequestBody,
    ExportTagToLabelStudioTasksRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, ExportTagToLabelStudioTasksRequestBodySanitizer, ExportTagToLabelStudioTasksRequestBodyValidator);
    const {
        datasetId,
        tagId,
        
        
        
        
        
        
        
        
    } =  await validateAndSanitize(req.params, ExportTagToLabelStudioTasksRequestParamsSanitizer, ExportTagToLabelStudioTasksRequestParamsValidator);
    const {
        
        
        expiresIn,
        accessControl,
        fileNameFormat,
        includeMetaData,
        format,
        previewExample,
        pageSize,
        pageOffset,
    } = await validateAndSanitize(req.query, ExportTagToLabelStudioTasksRequestQuerySanitizer, ExportTagToLabelStudioTasksRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        LabelStudioTasksStringify,
        validateAndRespond({ id, ...rest }, LabelStudioTasksFromJSON, LabelStudioTasksValidator),
    );
}



/v1/datasets/{datasetId}/tags/{tagId}/export/Sama/tasks

export const exportTagToSamaTasksFN: RequestHandler<
    ExportTagToSamaTasksRequestParams,
    SamaTasks | ApiErrorResponse,
    ExportTagToSamaTasksRequestBody,
    ExportTagToSamaTasksRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, ExportTagToSamaTasksRequestBodySanitizer, ExportTagToSamaTasksRequestBodyValidator);
    const {
        datasetId,
        tagId,
        
        
        
        
        
        
        
        
    } =  await validateAndSanitize(req.params, ExportTagToSamaTasksRequestParamsSanitizer, ExportTagToSamaTasksRequestParamsValidator);
    const {
        
        
        expiresIn,
        accessControl,
        fileNameFormat,
        includeMetaData,
        format,
        previewExample,
        pageSize,
        pageOffset,
    } = await validateAndSanitize(req.query, ExportTagToSamaTasksRequestQuerySanitizer, ExportTagToSamaTasksRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        SamaTasksStringify,
        validateAndRespond({ id, ...rest }, SamaTasksFromJSON, SamaTasksValidator),
    );
}



/v1/datasets/{datasetId}/tags/{tagId}/filenames

export const getFilenamesByTagIdFN: RequestHandler<
    GetFilenamesByTagIdRequestParams,
    TagFilenamesData | ApiErrorResponse,
    GetFilenamesByTagIdRequestBody,
    GetFilenamesByTagIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetFilenamesByTagIdRequestBodySanitizer, GetFilenamesByTagIdRequestBodyValidator);
    const {
        datasetId,
        tagId,
    } =  await validateAndSanitize(req.params, GetFilenamesByTagIdRequestParamsSanitizer, GetFilenamesByTagIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, GetFilenamesByTagIdRequestQuerySanitizer, GetFilenamesByTagIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        TagFilenamesDataStringify,
        validateAndRespond({ id, ...rest }, TagFilenamesDataFromJSON, TagFilenamesDataValidator),
    );
}



/v1/datasets/{datasetId}/tags/{tagId}

export const getTagByTagIdFN: RequestHandler<
    GetTagByTagIdRequestParams,
    TagData | ApiErrorResponse,
    GetTagByTagIdRequestBody,
    GetTagByTagIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetTagByTagIdRequestBodySanitizer, GetTagByTagIdRequestBodyValidator);
    const {
        datasetId,
        tagId,
    } =  await validateAndSanitize(req.params, GetTagByTagIdRequestParamsSanitizer, GetTagByTagIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, GetTagByTagIdRequestQuerySanitizer, GetTagByTagIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        TagDataStringify,
        validateAndRespond({ id, ...rest }, TagDataFromJSON, TagDataValidator),
    );
}



/v1/datasets/{datasetId}/tags
const TagDataArrayStringify = fastJSONArrayify(TagDataStringifyShape);
export const getTagsByDatasetIdFN: RequestHandler<
    GetTagsByDatasetIdRequestParams,
    TagData[] | ApiErrorResponse,
    GetTagsByDatasetIdRequestBody,
    GetTagsByDatasetIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetTagsByDatasetIdRequestBodySanitizer, GetTagsByDatasetIdRequestBodyValidator);
    const {
        datasetId,
    } =  await validateAndSanitize(req.params, GetTagsByDatasetIdRequestParamsSanitizer, GetTagsByDatasetIdRequestParamsValidator);
    const {
        
    } = await validateAndSanitize(req.query, GetTagsByDatasetIdRequestQuerySanitizer, GetTagsByDatasetIdRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        TagDataArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, TagDataFromJSON, TagDataValidator);
        }),
    );
}



/v1/datasets/{datasetId}/tags/arithmetics

export const performTagArithmeticsFN: RequestHandler<
    PerformTagArithmeticsRequestParams,
    TagArithmeticsResponse | ApiErrorResponse,
    PerformTagArithmeticsRequestBody,
    PerformTagArithmeticsRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, PerformTagArithmeticsRequestBodySanitizer, PerformTagArithmeticsRequestBodyValidator);
    const {
        
        datasetId,
    } =  await validateAndSanitize(req.params, PerformTagArithmeticsRequestParamsSanitizer, PerformTagArithmeticsRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, PerformTagArithmeticsRequestQuerySanitizer, PerformTagArithmeticsRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        TagArithmeticsResponseStringify,
        validateAndRespond({ id, ...rest }, TagArithmeticsResponseFromJSON, TagArithmeticsResponseValidator),
    );
}



/v1/datasets/{datasetId}/tags/arithmetics/bitmask

export const performTagArithmeticsBitmaskFN: RequestHandler<
    PerformTagArithmeticsBitmaskRequestParams,
    TagBitMaskResponse | ApiErrorResponse,
    PerformTagArithmeticsBitmaskRequestBody,
    PerformTagArithmeticsBitmaskRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, PerformTagArithmeticsBitmaskRequestBodySanitizer, PerformTagArithmeticsBitmaskRequestBodyValidator);
    const {
        
        datasetId,
    } =  await validateAndSanitize(req.params, PerformTagArithmeticsBitmaskRequestParamsSanitizer, PerformTagArithmeticsBitmaskRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, PerformTagArithmeticsBitmaskRequestQuerySanitizer, PerformTagArithmeticsBitmaskRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        TagBitMaskResponseStringify,
        validateAndRespond({ id, ...rest }, TagBitMaskResponseFromJSON, TagBitMaskResponseValidator),
    );
}



/v1/datasets/{datasetId}/tags/{tagId}

export const updateTagByTagIdFN: RequestHandler<
    UpdateTagByTagIdRequestParams,
     | ApiErrorResponse,
    UpdateTagByTagIdRequestBody,
    UpdateTagByTagIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, UpdateTagByTagIdRequestBodySanitizer, UpdateTagByTagIdRequestBodyValidator);
    const {
        
        datasetId,
        tagId,
    } =  await validateAndSanitize(req.params, UpdateTagByTagIdRequestParamsSanitizer, UpdateTagByTagIdRequestParamsValidator);
    const {
        
        
        
    } = await validateAndSanitize(req.query, UpdateTagByTagIdRequestQuerySanitizer, UpdateTagByTagIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        Stringify,
        validateAndRespond({ id, ...rest }, FromJSON, Validator),
    );
}



/v1/datasets/{datasetId}/tags/upsize

export const upsizeTagsByDatasetIdFN: RequestHandler<
    UpsizeTagsByDatasetIdRequestParams,
    CreateEntityResponse | ApiErrorResponse,
    UpsizeTagsByDatasetIdRequestBody,
    UpsizeTagsByDatasetIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, UpsizeTagsByDatasetIdRequestBodySanitizer, UpsizeTagsByDatasetIdRequestBodyValidator);
    const {
        
        datasetId,
    } =  await validateAndSanitize(req.params, UpsizeTagsByDatasetIdRequestParamsSanitizer, UpsizeTagsByDatasetIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, UpsizeTagsByDatasetIdRequestQuerySanitizer, UpsizeTagsByDatasetIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        CreateEntityResponseStringify,
        validateAndRespond({ id, ...rest }, CreateEntityResponseFromJSON, CreateEntityResponseValidator),
    );
}


*/
/* LIGHTLY CUSTOM END -> api */
/**
 * TagsApi - interface
 *
 * @export
 * @interface TagsApiInterface
 */
export interface TagsApiInterface {
    /**
     * create the intitial tag for a dataset which then locks the dataset
     * @summary Create initial tag by dataset id
     * @param {InitialTagCreateRequest} initialTagCreateRequest
     * @param {string} datasetId ObjectId of the dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    createInitialTagByDatasetIdRaw(requestParameters: CreateInitialTagByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>>;
    /**
     * create the intitial tag for a dataset which then locks the dataset
     * Create initial tag by dataset id
     */
    createInitialTagByDatasetId(requestParameters: CreateInitialTagByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse>;
    /**
     * create new tag for dataset
     * @summary Create tag by dataset id
     * @param {TagCreateRequest} tagCreateRequest
     * @param {string} datasetId ObjectId of the dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    createTagByDatasetIdRaw(requestParameters: CreateTagByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>>;
    /**
     * create new tag for dataset
     * Create tag by dataset id
     */
    createTagByDatasetId(requestParameters: CreateTagByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse>;
    /**
     * delete a specific tag if its a leaf-tag (e.g is not a dependency of another tag)
     * @summary Delete tag by id
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} tagId ObjectId of the tag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    deleteTagByTagIdRaw(requestParameters: DeleteTagByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;
    /**
     * delete a specific tag if its a leaf-tag (e.g is not a dependency of another tag)
     * Delete tag by id
     */
    deleteTagByTagId(requestParameters: DeleteTagByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;
    /**
     * DEPRECATED - Download a zip file of the samples of a tag. Limited to 1000 images
     * @summary Download zip of samples by tag id
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} tagId ObjectId of the tag
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    downloadZipOfSamplesByTagIdRaw(requestParameters: DownloadZipOfSamplesByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;
    /**
     * DEPRECATED - Download a zip file of the samples of a tag. Limited to 1000 images
     * Download zip of samples by tag id
     */
    downloadZipOfSamplesByTagId(requestParameters: DownloadZipOfSamplesByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;
    /**
     * Export the samples filenames of a specific tag
     * @summary Export tag to basic filenames
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} tagId ObjectId of the tag
     * @param {number} [expiresIn] If defined, the URLs provided will only be valid for amount of seconds from time of issuence. If not defined, the URls will be valid indefinitely.
     * @param {string} [accessControl] which access control name to be used
     * @param {FileNameFormat} [fileNameFormat]
     * @param {boolean} [includeMetaData] if true, will also include metadata
     * @param {FileOutputFormat} [format]
     * @param {boolean} [previewExample] if true, will generate a preview example of how the structure will look
     * @param {number} [pageSize] pagination size/limit of the number of samples to return
     * @param {number} [pageOffset] pagination offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    exportTagToBasicFilenamesRaw(requestParameters: ExportTagToBasicFilenamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;
    /**
     * Export the samples filenames of a specific tag
     * Export tag to basic filenames
     */
    exportTagToBasicFilenames(requestParameters: ExportTagToBasicFilenamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;
    /**
     * Export the samples filenames to map with their readURL.
     * @summary Export tag to basic filenames and read URLs
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} tagId ObjectId of the tag
     * @param {FileOutputFormat} [format]
     * @param {boolean} [previewExample] if true, will generate a preview example of how the structure will look
     * @param {number} [pageSize] pagination size/limit of the number of samples to return
     * @param {number} [pageOffset] pagination offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    exportTagToBasicFilenamesAndReadUrlsRaw(requestParameters: ExportTagToBasicFilenamesAndReadUrlsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilenameAndReadUrls>>;
    /**
     * Export the samples filenames to map with their readURL.
     * Export tag to basic filenames and read URLs
     */
    exportTagToBasicFilenamesAndReadUrls(requestParameters: ExportTagToBasicFilenamesAndReadUrlsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilenameAndReadUrls>;
    /**
     * DEPRECATED - Please use V4 unless there is a specific need to use the LabelBox V3 API. Export samples of a tag as a json for importing into LabelBox as outlined here; https://docs.labelbox.com/v3/reference/image ```openapi\\+warning The image URLs are special in that the resource can be accessed by anyone in possession of said URL for the time specified by the expiresIn query param ```
     * @summary Export tag to LabelBox data rows
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} tagId ObjectId of the tag
     * @param {number} [expiresIn] If defined, the URLs provided will only be valid for amount of seconds from time of issuence. If not defined, the URls will be valid indefinitely.
     * @param {string} [accessControl] which access control name to be used
     * @param {FileNameFormat} [fileNameFormat]
     * @param {boolean} [includeMetaData] if true, will also include metadata
     * @param {FileOutputFormat} [format]
     * @param {boolean} [previewExample] if true, will generate a preview example of how the structure will look
     * @param {number} [pageSize] pagination size/limit of the number of samples to return
     * @param {number} [pageOffset] pagination offset
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    exportTagToLabelBoxDataRowsRaw(requestParameters: ExportTagToLabelBoxDataRowsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LabelBoxDataRows>>;
    /**
     * DEPRECATED - Please use V4 unless there is a specific need to use the LabelBox V3 API. Export samples of a tag as a json for importing into LabelBox as outlined here; https://docs.labelbox.com/v3/reference/image ```openapi\\+warning The image URLs are special in that the resource can be accessed by anyone in possession of said URL for the time specified by the expiresIn query param ```
     * Export tag to LabelBox data rows
     */
    exportTagToLabelBoxDataRows(requestParameters: ExportTagToLabelBoxDataRowsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LabelBoxDataRows>;
    /**
     * Export samples of a tag as a json for importing into LabelBox as outlined here; https://docs.labelbox.com/v4/reference/image ```openapi\\+warning The image URLs are special in that the resource can be accessed by anyone in possession of said URL for the time specified by the expiresIn query param ```
     * @summary Export tag to LabelBox V4 data rows
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} tagId ObjectId of the tag
     * @param {number} [expiresIn] If defined, the URLs provided will only be valid for amount of seconds from time of issuence. If not defined, the URls will be valid indefinitely.
     * @param {string} [accessControl] which access control name to be used
     * @param {FileNameFormat} [fileNameFormat]
     * @param {boolean} [includeMetaData] if true, will also include metadata
     * @param {FileOutputFormat} [format]
     * @param {boolean} [previewExample] if true, will generate a preview example of how the structure will look
     * @param {number} [pageSize] pagination size/limit of the number of samples to return
     * @param {number} [pageOffset] pagination offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    exportTagToLabelBoxV4DataRowsRaw(requestParameters: ExportTagToLabelBoxV4DataRowsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LabelBoxV4DataRows>>;
    /**
     * Export samples of a tag as a json for importing into LabelBox as outlined here; https://docs.labelbox.com/v4/reference/image ```openapi\\+warning The image URLs are special in that the resource can be accessed by anyone in possession of said URL for the time specified by the expiresIn query param ```
     * Export tag to LabelBox V4 data rows
     */
    exportTagToLabelBoxV4DataRows(requestParameters: ExportTagToLabelBoxV4DataRowsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LabelBoxV4DataRows>;
    /**
     * Export samples of a tag as a json for importing into LabelStudio as outlined here; https://labelstud.io/guide/tasks.html#Basic-Label-Studio-JSON-format ```openapi\\+warning The image URLs are special in that the resource can be accessed by anyone in possession of said URL for the time specified by the expiresIn query param ```
     * @summary Export tag to LabelStudio tasks
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} tagId ObjectId of the tag
     * @param {number} [expiresIn] If defined, the URLs provided will only be valid for amount of seconds from time of issuence. If not defined, the URls will be valid indefinitely.
     * @param {string} [accessControl] which access control name to be used
     * @param {FileNameFormat} [fileNameFormat]
     * @param {boolean} [includeMetaData] if true, will also include metadata
     * @param {FileOutputFormat} [format]
     * @param {boolean} [previewExample] if true, will generate a preview example of how the structure will look
     * @param {number} [pageSize] pagination size/limit of the number of samples to return
     * @param {number} [pageOffset] pagination offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    exportTagToLabelStudioTasksRaw(requestParameters: ExportTagToLabelStudioTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LabelStudioTasks>>;
    /**
     * Export samples of a tag as a json for importing into LabelStudio as outlined here; https://labelstud.io/guide/tasks.html#Basic-Label-Studio-JSON-format ```openapi\\+warning The image URLs are special in that the resource can be accessed by anyone in possession of said URL for the time specified by the expiresIn query param ```
     * Export tag to LabelStudio tasks
     */
    exportTagToLabelStudioTasks(requestParameters: ExportTagToLabelStudioTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LabelStudioTasks>;
    /**
     * Export samples of a tag as a json for importing into Sama as tasks with the upload form or via the API as outlined here; - https://docs.sama.com/reference/taskcreate - https://docs.sama.com/reference/createbatch  ```openapi\\+warning The image URLs are special in that the resource can be accessed by anyone in possession of said URL for the time specified by the expiresIn query param ```
     * @summary Export tag to Sama tasks
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} tagId ObjectId of the tag
     * @param {number} [expiresIn] If defined, the URLs provided will only be valid for amount of seconds from time of issuence. If not defined, the URls will be valid indefinitely.
     * @param {string} [accessControl] which access control name to be used
     * @param {FileNameFormat} [fileNameFormat]
     * @param {boolean} [includeMetaData] if true, will also include metadata
     * @param {FileOutputFormat} [format]
     * @param {boolean} [previewExample] if true, will generate a preview example of how the structure will look
     * @param {number} [pageSize] pagination size/limit of the number of samples to return
     * @param {number} [pageOffset] pagination offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    exportTagToSamaTasksRaw(requestParameters: ExportTagToSamaTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SamaTasks>>;
    /**
     * Export samples of a tag as a json for importing into Sama as tasks with the upload form or via the API as outlined here; - https://docs.sama.com/reference/taskcreate - https://docs.sama.com/reference/createbatch  ```openapi\\+warning The image URLs are special in that the resource can be accessed by anyone in possession of said URL for the time specified by the expiresIn query param ```
     * Export tag to Sama tasks
     */
    exportTagToSamaTasks(requestParameters: ExportTagToSamaTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SamaTasks>;
    /**
     * DEPRECATED, please use exportTagToBasicFilenames - Get list of filenames by tag.
     * @summary Get filenames by tag id
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} tagId ObjectId of the tag
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    getFilenamesByTagIdRaw(requestParameters: GetFilenamesByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TagFilenamesData>>;
    /**
     * DEPRECATED, please use exportTagToBasicFilenames - Get list of filenames by tag.
     * Get filenames by tag id
     */
    getFilenamesByTagId(requestParameters: GetFilenamesByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagFilenamesData>;
    /**
     * Get information about a specific tag
     * @summary Get tag by id
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} tagId ObjectId of the tag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    getTagByTagIdRaw(requestParameters: GetTagByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TagData>>;
    /**
     * Get information about a specific tag
     * Get tag by id
     */
    getTagByTagId(requestParameters: GetTagByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagData>;
    /**
     * Get all tags of a dataset
     * @summary Get tags by dataset id
     * @param {string} datasetId ObjectId of the dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    getTagsByDatasetIdRaw(requestParameters: GetTagsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TagData>>>;
    /**
     * Get all tags of a dataset
     * Get tags by dataset id
     */
    getTagsByDatasetId(requestParameters: GetTagsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TagData>>;
    /**
     * performs tag arithmetics to compute a new bitmask out of two existing tags and optionally create a tag for it
     * @summary Perform tag arithmetics
     * @param {TagArithmeticsRequest} tagArithmeticsRequest
     * @param {string} datasetId ObjectId of the dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    performTagArithmeticsRaw(requestParameters: PerformTagArithmeticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TagArithmeticsResponse>>;
    /**
     * performs tag arithmetics to compute a new bitmask out of two existing tags and optionally create a tag for it
     * Perform tag arithmetics
     */
    performTagArithmetics(requestParameters: PerformTagArithmeticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagArithmeticsResponse>;
    /**
     * DEPRECATED, use performTagArithmetics - Performs tag arithmetics to compute a new bitmask out of two existing tags. Does not create a new tag regardless if newTagName is provided
     * @summary Perform tag arithmetics bitmask
     * @param {TagArithmeticsRequest} tagArithmeticsRequest
     * @param {string} datasetId ObjectId of the dataset
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    performTagArithmeticsBitmaskRaw(requestParameters: PerformTagArithmeticsBitmaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TagBitMaskResponse>>;
    /**
     * DEPRECATED, use performTagArithmetics - Performs tag arithmetics to compute a new bitmask out of two existing tags. Does not create a new tag regardless if newTagName is provided
     * Perform tag arithmetics bitmask
     */
    performTagArithmeticsBitmask(requestParameters: PerformTagArithmeticsBitmaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagBitMaskResponse>;
    /**
     * update information about a specific tag
     * @summary Update tag by id
     * @param {TagUpdateRequest} tagUpdateRequest updated data for tag
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} tagId ObjectId of the tag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    updateTagByTagIdRaw(requestParameters: UpdateTagByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;
    /**
     * update information about a specific tag
     * Update tag by id
     */
    updateTagByTagId(requestParameters: UpdateTagByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;
    /**
     * Upsize all tags for the dataset to the current size of the dataset. Use this after adding more samples to a dataset with an initial-tag. | Creates a new tag holding all samples which are not yet in the initial-tag.
     * @summary Upsize tags by dataset id
     * @param {TagUpsizeRequest} tagUpsizeRequest
     * @param {string} datasetId ObjectId of the dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    upsizeTagsByDatasetIdRaw(requestParameters: UpsizeTagsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>>;
    /**
     * Upsize all tags for the dataset to the current size of the dataset. Use this after adding more samples to a dataset with an initial-tag. | Creates a new tag holding all samples which are not yet in the initial-tag.
     * Upsize tags by dataset id
     */
    upsizeTagsByDatasetId(requestParameters: UpsizeTagsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse>;
}
/**
 *
 */
export class TagsApi extends runtime.BaseAPI implements TagsApiInterface {
    /**
     * create the intitial tag for a dataset which then locks the dataset
     * Create initial tag by dataset id
     */
    async createInitialTagByDatasetIdRaw(requestParameters: CreateInitialTagByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>> {
        if (requestParameters.initialTagCreateRequest === null || requestParameters.initialTagCreateRequest === undefined) {
            throw new runtime.RequiredError('initialTagCreateRequest', 'Required parameter requestParameters.initialTagCreateRequest was null or undefined when calling createInitialTagByDatasetId.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling createInitialTagByDatasetId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags/initial`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InitialTagCreateRequestToJSON(requestParameters.initialTagCreateRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEntityResponseFromJSON(jsonValue));
    }
    /**
     * create the intitial tag for a dataset which then locks the dataset
     * Create initial tag by dataset id
     */
    async createInitialTagByDatasetId(requestParameters: CreateInitialTagByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse> {
        const response = await this.createInitialTagByDatasetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * create new tag for dataset
     * Create tag by dataset id
     */
    async createTagByDatasetIdRaw(requestParameters: CreateTagByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>> {
        if (requestParameters.tagCreateRequest === null || requestParameters.tagCreateRequest === undefined) {
            throw new runtime.RequiredError('tagCreateRequest', 'Required parameter requestParameters.tagCreateRequest was null or undefined when calling createTagByDatasetId.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling createTagByDatasetId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagCreateRequestToJSON(requestParameters.tagCreateRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEntityResponseFromJSON(jsonValue));
    }
    /**
     * create new tag for dataset
     * Create tag by dataset id
     */
    async createTagByDatasetId(requestParameters: CreateTagByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse> {
        const response = await this.createTagByDatasetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * delete a specific tag if its a leaf-tag (e.g is not a dependency of another tag)
     * Delete tag by id
     */
    async deleteTagByTagIdRaw(requestParameters: DeleteTagByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling deleteTagByTagId.');
        }
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId', 'Required parameter requestParameters.tagId was null or undefined when calling deleteTagByTagId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags/{tagId}`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * delete a specific tag if its a leaf-tag (e.g is not a dependency of another tag)
     * Delete tag by id
     */
    async deleteTagByTagId(requestParameters: DeleteTagByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTagByTagIdRaw(requestParameters, initOverrides);
    }
    /**
     * DEPRECATED - Download a zip file of the samples of a tag. Limited to 1000 images
     * Download zip of samples by tag id
     */
    async downloadZipOfSamplesByTagIdRaw(requestParameters: DownloadZipOfSamplesByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling downloadZipOfSamplesByTagId.');
        }
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId', 'Required parameter requestParameters.tagId was null or undefined when calling downloadZipOfSamplesByTagId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags/{tagId}/export/zip`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.BlobApiResponse(response);
    }
    /**
     * DEPRECATED - Download a zip file of the samples of a tag. Limited to 1000 images
     * Download zip of samples by tag id
     */
    async downloadZipOfSamplesByTagId(requestParameters: DownloadZipOfSamplesByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadZipOfSamplesByTagIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Export the samples filenames of a specific tag
     * Export tag to basic filenames
     */
    async exportTagToBasicFilenamesRaw(requestParameters: ExportTagToBasicFilenamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling exportTagToBasicFilenames.');
        }
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId', 'Required parameter requestParameters.tagId was null or undefined when calling exportTagToBasicFilenames.');
        }
        const queryParameters: any = {};
        if (requestParameters.expiresIn !== undefined) {
            queryParameters['expiresIn'] = requestParameters.expiresIn;
        }
        if (requestParameters.accessControl !== undefined) {
            queryParameters['accessControl'] = requestParameters.accessControl;
        }
        if (requestParameters.fileNameFormat !== undefined) {
            queryParameters['fileNameFormat'] = requestParameters.fileNameFormat;
        }
        if (requestParameters.includeMetaData !== undefined) {
            queryParameters['includeMetaData'] = requestParameters.includeMetaData;
        }
        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }
        if (requestParameters.previewExample !== undefined) {
            queryParameters['previewExample'] = requestParameters.previewExample;
        }
        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }
        if (requestParameters.pageOffset !== undefined) {
            queryParameters['pageOffset'] = requestParameters.pageOffset;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags/{tagId}/export/basic/filenames`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.TextApiResponse(response) as any;
    }
    /**
     * Export the samples filenames of a specific tag
     * Export tag to basic filenames
     */
    async exportTagToBasicFilenames(requestParameters: ExportTagToBasicFilenamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.exportTagToBasicFilenamesRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Export the samples filenames to map with their readURL.
     * Export tag to basic filenames and read URLs
     */
    async exportTagToBasicFilenamesAndReadUrlsRaw(requestParameters: ExportTagToBasicFilenamesAndReadUrlsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilenameAndReadUrls>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling exportTagToBasicFilenamesAndReadUrls.');
        }
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId', 'Required parameter requestParameters.tagId was null or undefined when calling exportTagToBasicFilenamesAndReadUrls.');
        }
        const queryParameters: any = {};
        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }
        if (requestParameters.previewExample !== undefined) {
            queryParameters['previewExample'] = requestParameters.previewExample;
        }
        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }
        if (requestParameters.pageOffset !== undefined) {
            queryParameters['pageOffset'] = requestParameters.pageOffset;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags/{tagId}/export/basic/filenamesAndReadUrls`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FilenameAndReadUrlsFromJSON(jsonValue));
    }
    /**
     * Export the samples filenames to map with their readURL.
     * Export tag to basic filenames and read URLs
     */
    async exportTagToBasicFilenamesAndReadUrls(requestParameters: ExportTagToBasicFilenamesAndReadUrlsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilenameAndReadUrls> {
        const response = await this.exportTagToBasicFilenamesAndReadUrlsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * DEPRECATED - Please use V4 unless there is a specific need to use the LabelBox V3 API. Export samples of a tag as a json for importing into LabelBox as outlined here; https://docs.labelbox.com/v3/reference/image ```openapi\\+warning The image URLs are special in that the resource can be accessed by anyone in possession of said URL for the time specified by the expiresIn query param ```
     * Export tag to LabelBox data rows
     */
    async exportTagToLabelBoxDataRowsRaw(requestParameters: ExportTagToLabelBoxDataRowsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LabelBoxDataRows>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling exportTagToLabelBoxDataRows.');
        }
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId', 'Required parameter requestParameters.tagId was null or undefined when calling exportTagToLabelBoxDataRows.');
        }
        const queryParameters: any = {};
        if (requestParameters.expiresIn !== undefined) {
            queryParameters['expiresIn'] = requestParameters.expiresIn;
        }
        if (requestParameters.accessControl !== undefined) {
            queryParameters['accessControl'] = requestParameters.accessControl;
        }
        if (requestParameters.fileNameFormat !== undefined) {
            queryParameters['fileNameFormat'] = requestParameters.fileNameFormat;
        }
        if (requestParameters.includeMetaData !== undefined) {
            queryParameters['includeMetaData'] = requestParameters.includeMetaData;
        }
        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }
        if (requestParameters.previewExample !== undefined) {
            queryParameters['previewExample'] = requestParameters.previewExample;
        }
        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }
        if (requestParameters.pageOffset !== undefined) {
            queryParameters['pageOffset'] = requestParameters.pageOffset;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags/{tagId}/export/LabelBox/datarows`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => LabelBoxDataRowsFromJSON(jsonValue));
    }
    /**
     * DEPRECATED - Please use V4 unless there is a specific need to use the LabelBox V3 API. Export samples of a tag as a json for importing into LabelBox as outlined here; https://docs.labelbox.com/v3/reference/image ```openapi\\+warning The image URLs are special in that the resource can be accessed by anyone in possession of said URL for the time specified by the expiresIn query param ```
     * Export tag to LabelBox data rows
     */
    async exportTagToLabelBoxDataRows(requestParameters: ExportTagToLabelBoxDataRowsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LabelBoxDataRows> {
        const response = await this.exportTagToLabelBoxDataRowsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Export samples of a tag as a json for importing into LabelBox as outlined here; https://docs.labelbox.com/v4/reference/image ```openapi\\+warning The image URLs are special in that the resource can be accessed by anyone in possession of said URL for the time specified by the expiresIn query param ```
     * Export tag to LabelBox V4 data rows
     */
    async exportTagToLabelBoxV4DataRowsRaw(requestParameters: ExportTagToLabelBoxV4DataRowsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LabelBoxV4DataRows>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling exportTagToLabelBoxV4DataRows.');
        }
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId', 'Required parameter requestParameters.tagId was null or undefined when calling exportTagToLabelBoxV4DataRows.');
        }
        const queryParameters: any = {};
        if (requestParameters.expiresIn !== undefined) {
            queryParameters['expiresIn'] = requestParameters.expiresIn;
        }
        if (requestParameters.accessControl !== undefined) {
            queryParameters['accessControl'] = requestParameters.accessControl;
        }
        if (requestParameters.fileNameFormat !== undefined) {
            queryParameters['fileNameFormat'] = requestParameters.fileNameFormat;
        }
        if (requestParameters.includeMetaData !== undefined) {
            queryParameters['includeMetaData'] = requestParameters.includeMetaData;
        }
        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }
        if (requestParameters.previewExample !== undefined) {
            queryParameters['previewExample'] = requestParameters.previewExample;
        }
        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }
        if (requestParameters.pageOffset !== undefined) {
            queryParameters['pageOffset'] = requestParameters.pageOffset;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags/{tagId}/export/LabelBoxV4/datarows`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => LabelBoxV4DataRowsFromJSON(jsonValue));
    }
    /**
     * Export samples of a tag as a json for importing into LabelBox as outlined here; https://docs.labelbox.com/v4/reference/image ```openapi\\+warning The image URLs are special in that the resource can be accessed by anyone in possession of said URL for the time specified by the expiresIn query param ```
     * Export tag to LabelBox V4 data rows
     */
    async exportTagToLabelBoxV4DataRows(requestParameters: ExportTagToLabelBoxV4DataRowsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LabelBoxV4DataRows> {
        const response = await this.exportTagToLabelBoxV4DataRowsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Export samples of a tag as a json for importing into LabelStudio as outlined here; https://labelstud.io/guide/tasks.html#Basic-Label-Studio-JSON-format ```openapi\\+warning The image URLs are special in that the resource can be accessed by anyone in possession of said URL for the time specified by the expiresIn query param ```
     * Export tag to LabelStudio tasks
     */
    async exportTagToLabelStudioTasksRaw(requestParameters: ExportTagToLabelStudioTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LabelStudioTasks>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling exportTagToLabelStudioTasks.');
        }
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId', 'Required parameter requestParameters.tagId was null or undefined when calling exportTagToLabelStudioTasks.');
        }
        const queryParameters: any = {};
        if (requestParameters.expiresIn !== undefined) {
            queryParameters['expiresIn'] = requestParameters.expiresIn;
        }
        if (requestParameters.accessControl !== undefined) {
            queryParameters['accessControl'] = requestParameters.accessControl;
        }
        if (requestParameters.fileNameFormat !== undefined) {
            queryParameters['fileNameFormat'] = requestParameters.fileNameFormat;
        }
        if (requestParameters.includeMetaData !== undefined) {
            queryParameters['includeMetaData'] = requestParameters.includeMetaData;
        }
        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }
        if (requestParameters.previewExample !== undefined) {
            queryParameters['previewExample'] = requestParameters.previewExample;
        }
        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }
        if (requestParameters.pageOffset !== undefined) {
            queryParameters['pageOffset'] = requestParameters.pageOffset;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags/{tagId}/export/LabelStudio/tasks`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => LabelStudioTasksFromJSON(jsonValue));
    }
    /**
     * Export samples of a tag as a json for importing into LabelStudio as outlined here; https://labelstud.io/guide/tasks.html#Basic-Label-Studio-JSON-format ```openapi\\+warning The image URLs are special in that the resource can be accessed by anyone in possession of said URL for the time specified by the expiresIn query param ```
     * Export tag to LabelStudio tasks
     */
    async exportTagToLabelStudioTasks(requestParameters: ExportTagToLabelStudioTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LabelStudioTasks> {
        const response = await this.exportTagToLabelStudioTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Export samples of a tag as a json for importing into Sama as tasks with the upload form or via the API as outlined here; - https://docs.sama.com/reference/taskcreate - https://docs.sama.com/reference/createbatch  ```openapi\\+warning The image URLs are special in that the resource can be accessed by anyone in possession of said URL for the time specified by the expiresIn query param ```
     * Export tag to Sama tasks
     */
    async exportTagToSamaTasksRaw(requestParameters: ExportTagToSamaTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SamaTasks>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling exportTagToSamaTasks.');
        }
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId', 'Required parameter requestParameters.tagId was null or undefined when calling exportTagToSamaTasks.');
        }
        const queryParameters: any = {};
        if (requestParameters.expiresIn !== undefined) {
            queryParameters['expiresIn'] = requestParameters.expiresIn;
        }
        if (requestParameters.accessControl !== undefined) {
            queryParameters['accessControl'] = requestParameters.accessControl;
        }
        if (requestParameters.fileNameFormat !== undefined) {
            queryParameters['fileNameFormat'] = requestParameters.fileNameFormat;
        }
        if (requestParameters.includeMetaData !== undefined) {
            queryParameters['includeMetaData'] = requestParameters.includeMetaData;
        }
        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }
        if (requestParameters.previewExample !== undefined) {
            queryParameters['previewExample'] = requestParameters.previewExample;
        }
        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }
        if (requestParameters.pageOffset !== undefined) {
            queryParameters['pageOffset'] = requestParameters.pageOffset;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags/{tagId}/export/Sama/tasks`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SamaTasksFromJSON(jsonValue));
    }
    /**
     * Export samples of a tag as a json for importing into Sama as tasks with the upload form or via the API as outlined here; - https://docs.sama.com/reference/taskcreate - https://docs.sama.com/reference/createbatch  ```openapi\\+warning The image URLs are special in that the resource can be accessed by anyone in possession of said URL for the time specified by the expiresIn query param ```
     * Export tag to Sama tasks
     */
    async exportTagToSamaTasks(requestParameters: ExportTagToSamaTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SamaTasks> {
        const response = await this.exportTagToSamaTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * DEPRECATED, please use exportTagToBasicFilenames - Get list of filenames by tag.
     * Get filenames by tag id
     */
    async getFilenamesByTagIdRaw(requestParameters: GetFilenamesByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TagFilenamesData>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getFilenamesByTagId.');
        }
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId', 'Required parameter requestParameters.tagId was null or undefined when calling getFilenamesByTagId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags/{tagId}/filenames`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TagFilenamesDataFromJSON(jsonValue));
    }
    /**
     * DEPRECATED, please use exportTagToBasicFilenames - Get list of filenames by tag.
     * Get filenames by tag id
     */
    async getFilenamesByTagId(requestParameters: GetFilenamesByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagFilenamesData> {
        const response = await this.getFilenamesByTagIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get information about a specific tag
     * Get tag by id
     */
    async getTagByTagIdRaw(requestParameters: GetTagByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TagData>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getTagByTagId.');
        }
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId', 'Required parameter requestParameters.tagId was null or undefined when calling getTagByTagId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags/{tagId}`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TagDataFromJSON(jsonValue));
    }
    /**
     * Get information about a specific tag
     * Get tag by id
     */
    async getTagByTagId(requestParameters: GetTagByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagData> {
        const response = await this.getTagByTagIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get all tags of a dataset
     * Get tags by dataset id
     */
    async getTagsByDatasetIdRaw(requestParameters: GetTagsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TagData>>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getTagsByDatasetId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TagDataFromJSON));
    }
    /**
     * Get all tags of a dataset
     * Get tags by dataset id
     */
    async getTagsByDatasetId(requestParameters: GetTagsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TagData>> {
        const response = await this.getTagsByDatasetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * performs tag arithmetics to compute a new bitmask out of two existing tags and optionally create a tag for it
     * Perform tag arithmetics
     */
    async performTagArithmeticsRaw(requestParameters: PerformTagArithmeticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TagArithmeticsResponse>> {
        if (requestParameters.tagArithmeticsRequest === null || requestParameters.tagArithmeticsRequest === undefined) {
            throw new runtime.RequiredError('tagArithmeticsRequest', 'Required parameter requestParameters.tagArithmeticsRequest was null or undefined when calling performTagArithmetics.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling performTagArithmetics.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags/arithmetics`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagArithmeticsRequestToJSON(requestParameters.tagArithmeticsRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TagArithmeticsResponseFromJSON(jsonValue));
    }
    /**
     * performs tag arithmetics to compute a new bitmask out of two existing tags and optionally create a tag for it
     * Perform tag arithmetics
     */
    async performTagArithmetics(requestParameters: PerformTagArithmeticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagArithmeticsResponse> {
        const response = await this.performTagArithmeticsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * DEPRECATED, use performTagArithmetics - Performs tag arithmetics to compute a new bitmask out of two existing tags. Does not create a new tag regardless if newTagName is provided
     * Perform tag arithmetics bitmask
     */
    async performTagArithmeticsBitmaskRaw(requestParameters: PerformTagArithmeticsBitmaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TagBitMaskResponse>> {
        if (requestParameters.tagArithmeticsRequest === null || requestParameters.tagArithmeticsRequest === undefined) {
            throw new runtime.RequiredError('tagArithmeticsRequest', 'Required parameter requestParameters.tagArithmeticsRequest was null or undefined when calling performTagArithmeticsBitmask.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling performTagArithmeticsBitmask.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags/arithmetics/bitmask`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagArithmeticsRequestToJSON(requestParameters.tagArithmeticsRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TagBitMaskResponseFromJSON(jsonValue));
    }
    /**
     * DEPRECATED, use performTagArithmetics - Performs tag arithmetics to compute a new bitmask out of two existing tags. Does not create a new tag regardless if newTagName is provided
     * Perform tag arithmetics bitmask
     */
    async performTagArithmeticsBitmask(requestParameters: PerformTagArithmeticsBitmaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagBitMaskResponse> {
        const response = await this.performTagArithmeticsBitmaskRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * update information about a specific tag
     * Update tag by id
     */
    async updateTagByTagIdRaw(requestParameters: UpdateTagByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tagUpdateRequest === null || requestParameters.tagUpdateRequest === undefined) {
            throw new runtime.RequiredError('tagUpdateRequest', 'Required parameter requestParameters.tagUpdateRequest was null or undefined when calling updateTagByTagId.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling updateTagByTagId.');
        }
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId', 'Required parameter requestParameters.tagId was null or undefined when calling updateTagByTagId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags/{tagId}`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TagUpdateRequestToJSON(requestParameters.tagUpdateRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * update information about a specific tag
     * Update tag by id
     */
    async updateTagByTagId(requestParameters: UpdateTagByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTagByTagIdRaw(requestParameters, initOverrides);
    }
    /**
     * Upsize all tags for the dataset to the current size of the dataset. Use this after adding more samples to a dataset with an initial-tag. | Creates a new tag holding all samples which are not yet in the initial-tag.
     * Upsize tags by dataset id
     */
    async upsizeTagsByDatasetIdRaw(requestParameters: UpsizeTagsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>> {
        if (requestParameters.tagUpsizeRequest === null || requestParameters.tagUpsizeRequest === undefined) {
            throw new runtime.RequiredError('tagUpsizeRequest', 'Required parameter requestParameters.tagUpsizeRequest was null or undefined when calling upsizeTagsByDatasetId.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling upsizeTagsByDatasetId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags/upsize`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagUpsizeRequestToJSON(requestParameters.tagUpsizeRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEntityResponseFromJSON(jsonValue));
    }
    /**
     * Upsize all tags for the dataset to the current size of the dataset. Use this after adding more samples to a dataset with an initial-tag. | Creates a new tag holding all samples which are not yet in the initial-tag.
     * Upsize tags by dataset id
     */
    async upsizeTagsByDatasetId(requestParameters: UpsizeTagsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse> {
        const response = await this.upsizeTagsByDatasetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
